import React from 'react'
import {Link} from 'react-router-dom'
import {Title} from "../components/Typo";

import {Button} from "../components/Buttons";
import {FeedbackForm} from "../components/Form";
import {AppContext} from "../store/context";

import arrow_down from "../static/icons/arrow_down.svg";
import arrow_up from "../static/icons/sort_arrow_up.svg";

import {Map} from "../components/Map";

import {ADDITIONAL_RESTAURANTS} from '../const';

export class Contacts extends React.Component {
    constructor() {
        super();

        this.state = {
            restaurants: [],
            showRestaurants: false,
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.context.context.processor.content.getRestaurants().then(res => {
            this.setState({
                restaurants: res.restaurants.concat(ADDITIONAL_RESTAURANTS)
            })
        })
    }

    toggleRestList() {
        this.setState({showRestaurants: !this.state.showRestaurants})
    }


    render() {
        return <div className="contacts reveal">
            <div className="container container-pd">
                <div className="contacts__restaurants">
                    <div className="contacts__restaurants-title">
                        <Title black={'контакты'}/>
                    </div>
                    <div className="contacts__restaurants-list">
                        <div className="contacts__restaurants-grid">
                            {this.state.restaurants.map(el => {
                                const link = el.slug === "evropolis"
                                    ? "/moscow"
                                    : el.slug === "moremall"
                                        ? "/sochi"
                                        : `/restaurants/${el.slug}`;
                                return <div
                                    className={`restaurant-contact ${this.state.showRestaurants ? 'restaurant-contact--show' : ''}`}>
                                    <div className="restaurant-contact__title">
                                        <Link to={link}>{el.title}</Link>
                                    </div>
                                    <div className="restaurant-contact__address text">{el.address}</div>
                                    <div className="restaurant-contact__phone text"><a
                                        href={`tel:${el.phone}`}>{el.phone}</a></div>
                                    <div className="restaurant-contact__operating text">
                                        График работы: {el.operating}
                                    </div>
                                    <div className="restaurant-contact__link text">
                                        <a target="_blank" href={el.googleMaps}>Открыть в Google Maps</a>
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className="contacts__restaurants-map">
                            <div className="contacts__restaurants-map--holder">
                                <Map height={'100%'}/>
                            </div>
                            {!this.state.showRestaurants ?
                                <div className="contacts__restaurants--show mobile" onClick={() => {
                                    this.toggleRestList()
                                }}>
                                    <span>Показать список </span>
                                    <span className={'icon'}><img src={arrow_down} alt=""/></span>
                                </div> : false}
                        </div>
                        {this.state.showRestaurants ?
                            <div onClick={() => {
                                this.toggleRestList()
                            }} className="contacts__restaurants--hide mobile">
                                <span>Скрыть список </span>
                                <span className={'icon'}><img src={arrow_down} alt=""/></span>
                            </div> : null}
                    </div>
                </div>
            </div>
            <div className="container container-pd">
                <div className="contacts__feedback">
                    <div className="contacts__feedback-testimonial">
                        <div className="title">
                            <span className='color-black'>{'мы создаем'}</span><br/>
                            <span className='color-green'>{'отличный'}</span><br/>
                            <span className='color-green'>{'сервис'}</span>
                        </div>
                        <div className="text">
                            Мы любим то, что делаем, и для нас очень важно, чтобы вы наслаждались вкусной едой. Если
                            вдруг что-то пошло не так и у вас есть замечания к качеству нашей работы или блюд, сообщите
                            нам. Мы обязательно исправимся и станем лучше.
                        </div>
                        <Button text={'меню'} onClick={() => {
                            this.props.history.push('/menu')
                        }}/>
                    </div>
                    <div className="contacts__feedback-form">
                        <FeedbackForm title={'напишите нам'}/>
                    </div>
                </div>
            </div>
        </div>
    }
}


Contacts.contextType = AppContext;
