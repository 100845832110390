import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/order`;

export class Order extends Base {
    checkout(data) {
        return this.fetchJSON(`${BASE_URL}/checkout`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(response => {
            return response.data
        });
    }

    get(hash) {
        return this.fetchJSON(`${BASE_URL}/${hash}`).then(response => {
            return response.data
        });
    }
}