import React from 'react'
import {useHistory} from 'react-router-dom'

import {RestaurantImagesSlider} from "../components/restaurant/Slider";
import {Title} from "../components/Typo";
import {ActionList} from "../components/action/Action";
import {LoaderFullsize} from "../components/Loader";
import {AppContext} from "../store/context";
import {STATIC_HOST} from "../const";
import {Button} from "../components/Buttons";

import restaurants from '../static/images/restaurants/restaurants.png'

import {Map} from '../components/Map'

export class RestaurantList extends React.Component {
    constructor(props, context) {
        super();

        this.processor = context.context.processor;

        this.state = {
            restaurants: [],
            loaded: false
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.processor.content.getRestaurants().then(response => {
            this.setState({
                restaurants: response.restaurants,
                loaded: true
            })
        })
    }

    render() {
        return <div className="restaurants-page reveal">
            <div className="restaurants-page--banner mobile" style={{backgroundImage: `url(${restaurants}`}}>
            </div>
            <div className="container container-pd">
                <div className="restaurants-page__holder">
                    <div className="restaurants-page__description">
                        <div className="title">
                            <span className='color-black'>Наши</span><br/>
                            <span className='color-black'>рестораны</span><br/>
                            <span className='color-green'>Кореана Light</span>
                        </div>
                        <div className="text">
                            Чтобы не стоять в очереди, <strong>оформляйте предзаказ</strong> блюд на нашем сайте.
                            Ваш заказ будет готов уже <strong>в течение 15 минут</strong>, остается
                            только выбрать удобный для Вас ресторан!
                        </div>
                        <div className="restaurants-page__buttons">
                            <Button color={'green'} text={'списком'} onClick={() => {
                                const elems = document.getElementsByClassName('restaurants-page__list')
                                if (elems.length > 0) {
                                    const rect = elems[0].getBoundingClientRect()

                                    window.scrollTo({
                                        top: rect.top,
                                        behavior: 'smooth' // smooth scroll
                                    });
                                }

                            }}/>
                            <Button color={'white'} text={'на карте'} onClick={() => {
                                const rect = document.getElementById('map').getBoundingClientRect()

                                window.scrollTo({
                                    top: rect.top,
                                    behavior: 'smooth' // smooth scroll
                                });
                            }}/>
                        </div>
                    </div>
                    <div className="restaurants-page__image">
                        <img src={restaurants} alt=""/>
                    </div>
                </div>
            </div>
            <div id="map"/>
            <div className="container-fluid restaurants-page__map">
                <div className="restaurants-page__map--holder">
                    <Map/>
                </div>
            </div>
            {this.state.loaded ? <div className="container restaurants-page__list appear">
                <div className="title">
                    <span className='color-black'>Адреса </span>
                    <span className='color-green'>ресторанов</span>
                </div>
                <br/> <br/>
                <RestaurauntItemList restaurants={this.state.restaurants}/>
            </div> : <LoaderFullsize/>}
        </div>
    }
}

export class Restaurant extends React.Component {
    constructor() {
        super();

        this.state = {
            restaurants: [],
            restaurant: null,
            actions: [],
            loaded: false

        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        const slug = this.props.match.params.slug;

        this.context.context.processor.content.getRestaurant(slug).then(response => {
            this.setState({
                restaurants: response.restaurants,
                restaurant: response.restaurant,
                actions: response.actions,
                loaded: true
            });

            return response
        }).then(response => {
            this.context.context.modals.action.dispatch({type: 'setList', payload: response.actions})
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.slug !== prevProps.match.params.slug) {
            this.setState({loaded: false});
            this.componentDidMount()
        }
    }

    render() {
        return this.state.loaded ?
            <div className="restaurant-page appear">
                <div className="restaurant-page--banner mobile" style={{
                    backgroundImage: `url(${this.state.restaurant.images.length
                        ? STATIC_HOST + this.state.restaurant.images[0].source
                        : restaurants}`
                }}/>
                <div className="container container-pd">
                    <div className="restaurant-page__holder">
                        <div className="restaurant-page__description">
                            <div className="title">
                                <span className='color-white'>Кореана light</span><br/>
                                <span className='color-white'>{this.state.restaurant.title}</span>
                            </div>
                            <div className="text">
                                {this.state.restaurant.description}
                            </div>
                            {this.state.restaurant.zone.active === true ?
                                <Button color={'white'} text={'Заказать здесь'} onClick={() => {
                                    this.props.history.push('/menu')
                                }}/> :
                                <Button color={'white'} text={'Временно недоступно'} disabled={true}/>
                            }
                        </div>
                        <div className="restaurant-page__image">
                            <img
                                src={this.state.restaurant.images.length ? STATIC_HOST + this.state.restaurant.images[0].source : restaurants}
                                alt=""/>
                        </div>
                    </div>
                </div>
                <div className="container restaurant-page__slider">
                    <Title black={'Галерея'}/>
                    {this.state.restaurant ? <RestaurantImagesSlider restaurant={this.state.restaurant}/> : null}
                </div>
                {this.state.actions.length
                    ? <div className="container restaurant-page__actions">
                        <Title black={'наши '} green={'акции'}/>
                        <ActionList actions={this.state.actions}/>
                    </div>
                    : null}
                {this.state.restaurants.length
                    ? <div className="container restaurant-page__list">
                        <Title black={'другие '} green={'рестораны'}/>
                        <RestaurauntItemList current={this.state.restaurant} restaurants={this.state.restaurants}/>
                    </div>
                    : null}
            </div> : <LoaderFullsize/>
    }
}

export const RestaurauntItemList = ({current, restaurants}) => {
    const history = useHistory();

    return <div className="restaurants-list">
        {restaurants.map(el => {
            return !current || el.slug !== current.slug ? <div className="restaurants-list__restaurant" onClick={() => {
                history.push(`/restaurants/${el.slug}`)
            }}>
                <div className="restaurants-list__restaurant--image"
                     style={{backgroundImage: `url(${el.images.length ? STATIC_HOST + el.images[0].source : ''})`}}/>
                <div className="restaurants-list__restaurant-info">
                    <div className="restaurants-list__restaurant--title">{el.title}</div>
                    <div className="restaurants-list__restaurant--address">{el.address}</div>
                </div>
            </div> : null
        })}
    </div>
};

Restaurant.contextType = AppContext;
RestaurantList.contextType = AppContext;
