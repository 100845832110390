import {useContext, useEffect, useState} from "react";
import {DEFAULT_DESCRIPTION, DEFAULT_TITLE} from "../const";
import {AppContext} from "../store/context";
import {useLocation} from 'react-router-dom'
import {Helmet} from 'react-helmet'

export const Head = () => {
    const {context} = useContext(AppContext);
    const [seoTitle, setTitle] = useState(DEFAULT_TITLE);
    const [description, setDescription] = useState(DEFAULT_DESCRIPTION);

    let location = useLocation();

    useEffect(() => {
        context.processor.content.getSeoData(location.pathname).then(res => {
            setTitle(res.seo?.title || DEFAULT_TITLE);
            setDescription(res.seo?.description || DEFAULT_DESCRIPTION);
            context.common.dispatch({type: 'setSeoText', payload: res.seo?.text})
            context.common.dispatch({type: 'setMenuBlockText', payload: res.seo?.menuBlock})
        })
    }, [location]);

    return <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={description}/>
        <link rel="canonical" href={`https://koreanalight.ru${location.pathname}`}/>
    </Helmet>
}

export const BottomSeo = () => {
    const {context} = useContext(AppContext);
    const [text, setText] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setText(context.common.state.seoText)
    }, [context.common.state.seoText]);

    return text ? <div className="container" style={{position: "relative"}}>
        <div className={`text-muted ${open ? '' : 'text-muted--collapsed'}`} dangerouslySetInnerHTML={{__html: text}}/>
        <div className={open ? 'text-muted--hide' : 'text-muted--show'} onClick={() => {setOpen(!open)}}>{open? 'скрыть' : 'подробнее'}</div>
    </div> : null
}

export const MenuSeo = () => {
    const {context} = useContext(AppContext);
    const [text, setText] = useState(null);

    useEffect(() => {
        setText(context.common.state.menuBlock)
    }, [context.common.state.menuBlock]);

    return text ? <div className={`catalog-text`} dangerouslySetInnerHTML={{__html: text}}/> : <div className="catalog-text"/>
}