import React from 'react'
import Slider from "react-slick";

import {TitleWGap} from "../components/Typo";
import {Button} from "../components/Buttons";
import {AppContext} from "../store/context";

import chief from "../static/images/about/chief_desktop.webp"
import arrow from "../static/images/about/arrow_about_page.svg"
import team from "../static/images/about/team.png"
import team2 from "../static/images/about/team2.png"
import team_mob from '../static/images/about/team_mob.png'


import broccoli from '../static/images/vacancies/broccoli.png'
import cherry from '../static/images/vacancies/cherry.png'
import lists from '../static/images/vacancies/lists.png'


export class About extends React.Component {
    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    render() {
        return <div className="about-page reveal">
            <div className="container container-pd">
                <div className="about__description">
                    <img src={cherry} alt="" className="about__description--cherry"/>

                    <div className="about__description-text">
                        <TitleWGap black={'команда'} green={'koreana light'}/>
                        <div className="text">
                            Мы ценим время наших клиентов, поэтому в каждом из ресторанов вы можете заказать еду заранее
                            и прийти уже на все готовое. Или же забрать свой заказа из ресторана. На самовывоз у нас
                            действует кешбэк 10% от суммы заказа.
                        </div>
                    </div>
                    <div className="about__description-image">
                        <img src={this.context.context.settings.isMobile ? team_mob : team} alt=""/>
                    </div>
                </div>
            </div>
            <div className="container">
                {this.context.context.settings.isMobile ? <AboutSliderMobile/> : <AboutSlider/>}
                <div className="about__vacancies">
                    {!this.context.context.settings.isMobile
                        ? <div className="about__vacancies-image">
                            <img src={team2} alt=""/>
                        </div> : null}
                    <div className="about__vacancies-text">
                        <img src={lists} alt="" className="about__vacancies-text--lists"/>
                        <img src={broccoli} alt="" className="about__vacancies-text--broccoli"/>
                        <TitleWGap black={'Хотите к нам в '} green={'команду?'}/>
                        <div className="text">
                            Мы любим то, что делаем, и для нас очень важно, чтобы вы наслаждались вкусной едой. Если
                            вдруг что-то пошло не так и у вас есть замечания к качеству нашей работы или блюд, сообщите
                            нам. Мы обязательно исправимся и станем лучше.
                        </div>
                        <Button text={'посмотреть вакансии'} onClick={() => {
                            this.props.history.push('/vacancies')
                        }}/>
                    </div>
                </div>
            </div>
        </div>
    }
}

const AboutSliderMobile = () => {
    return <div className="about__slider-holder">
        <div className="about__slider--arrow reveal-from-right">
            <img src={arrow} alt=""/>
            <span className='text--handwritten'>Мы делаем вкусное доступным!</span>
        </div>
        <div className="about__slider">
            <div className="slide about__slider-item">
                <div className="about__slider-item--image" style={{backgroundImage: `url(${team})`}}/>
                <div className="about__slider-item--title">Основатель</div>
            </div>
            <div className="slide about__slider-item">
                <div className="about__slider-item--image" style={{backgroundImage: `url(${chief})`}}/>
                <div className="about__slider-item--title">шеф повар</div>
            </div>
            <div className="slide about__slider-item">
                <div className="about__slider-item--image" style={{backgroundImage: `url(${team2})`}}/>
                <div className="about__slider-item--title">Бренд-менеджер</div>
            </div>
            <div className="slide about__slider-item">
                <div className="about__slider-item--image" style={{backgroundImage: `url(${team2})`}}/>
                <div className="about__slider-item--title">Основатель</div>
            </div>
            <div className="slide about__slider-item">
                <div className="about__slider-item--image" style={{backgroundImage: `url(${team})`}}/>
                <div className="about__slider-item--title">Основатель</div>
            </div>
        </div>
    </div>

}

const AboutSlider = () => {
    const settings = {
        slidesToShow: 4,
        centerMode: false,
        speed: 500,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    return <div className="about__slider-holder">
        <div className="about__slider--arrow reveal-from-left">
            <img src={arrow} alt=""/>
            <span className='text--handwritten'>Мы делаем вкусное доступным!</span>
        </div>
        <Slider className={'about__slider'} {...settings}>
            <div className="slide about__slider-item">
                <div className="about__slider-item--image" style={{backgroundImage: `url(${team})`}}/>
                <div className="about__slider-item--title">Основатель</div>
            </div>
            <div className="slide about__slider-item">
                <div className="about__slider-item--image" style={{backgroundImage: `url(${chief})`}}/>
                <div className="about__slider-item--title">шеф повар</div>
            </div>
            <div className="slide about__slider-item">
                <div className="about__slider-item--image" style={{backgroundImage: `url(${team2})`}}/>
                <div className="about__slider-item--title">Бренд-менеджер</div>
            </div>
            <div className="slide about__slider-item">
                <div className="about__slider-item--image" style={{backgroundImage: `url(${team2})`}}/>
                <div className="about__slider-item--title">Основатель</div>
            </div>
            <div className="slide about__slider-item">
                <div className="about__slider-item--image" style={{backgroundImage: `url(${team})`}}/>
                <div className="about__slider-item--title">Основатель</div>
            </div>
        </Slider>
    </div>
}

About.contextType = AppContext;