import MainLayout from "../layout/MainLayout"
import PageLayout from "../layout/PageLayout"

import {Main} from "../views/Main"
import {Catalog} from "../views/Catalog";
import {Restaurant, RestaurantList} from "../views/Restaurant";
import {Vacancies,} from "../views/Vacancy";
import {About} from "../views/About";
import {Contacts} from "../views/Contacts";
import {Cart} from "../views/Cart";
import {OrderSuccess} from "../views/OrderSuccess";
import {Personal} from "../views/Personal";
import {CheckoutPage} from "../views/Checkout";
import {PaymentDelivery} from "../views/content/PaymentDelivery"
import {Confidential} from "../views/content/Confidential"
import {Page404} from "../views/Page404";
import NoFooterLayout from "../layout/NoFooterLayout";
import {Moscow} from "../views/landings/Moscow";
import {Sochi} from "../views/landings/Sochi";
import {Service} from "../views/landings/Service";
import {Kimchi} from "../views/landings/Kimchi";

export const publicRoutes = [
    {
        path: "/",
        layout: MainLayout,
        component: Main,
    },
    {
        path: ["/menu", "/menu/:slug"],
        layout: MainLayout,
        component: Catalog,
    },
    {
        path: "/restaurants/",
        layout: PageLayout,
        component: RestaurantList,
    },
    {
        path: "/restaurants/:slug",
        layout: PageLayout,
        component: Restaurant,
    },
    {
        path: "/vacancies/",
        layout: PageLayout,
        component: Vacancies,
    },
    {
        path: "/contacts/",
        layout: PageLayout,
        component: Contacts,
    },
    {
        path: "/about",
        layout: PageLayout,
        component: About,
    },
    {
        path: "/cart",
        layout: PageLayout,
        component: Cart,
    },
    {
        path: "/order/success/:hash",
        layout: PageLayout,
        component: OrderSuccess
    },
    {
        path: "/personal",
        layout: PageLayout,
        component: Personal
    },
    {
        path: "/checkout",
        layout: PageLayout,
        component: CheckoutPage
    },
    {
        path: "/terms-of-use",
        layout: PageLayout,
        component: Confidential
    },
    {
        path: "/payment-and-delivery",
        layout: PageLayout,
        component: PaymentDelivery
    },
    {
        path: "/404",
        layout: NoFooterLayout,
        component: Page404
    },
    {
        path: "/moscow",
        layout: PageLayout,
        component: Moscow
    },
    {
        path: "/sochi",
        layout: PageLayout,
        component: Sochi
    },
    {
        path: "/sluzhba-kontrolja-kachestva-zabotlivyj-servis",
        layout: PageLayout,
        component: Service
    },
    {
        path: ["/feedback-:slug"],
        layout: PageLayout,
        component: Service
    },
    {
        path: ["/kimchi-glavnoe-bludo"],
        layout: PageLayout,
        component: Kimchi
    }
];

export default [
    ...publicRoutes
]
