import React, {useState} from 'react'
import {GoogleMap, InfoWindow, Marker, withGoogleMap} from 'react-google-maps';
import icon from '../static/icons/map_point.svg'
import iconIn from '../static/icons/map_point_inner.svg'

const points = [
    {
        lat: 59.990928,
        lng: 30.206129,
        name: 'ТРК Меркурий',
        link: '/restaurants/merkuriy',
        address: 'ул Савушкина, д 141 литера А',
        time: 'Ежедневно: 10:00 - 22:00',
        phone: '+7 (981) 024-21-51'
    },
    {
        lat: 60.004892,
        lng: 30.299811,
        name: 'ТРК Сити Молл',
        link: '/restaurants/city-mall',
        address: 'Коломяжский пр-кт, д 17 к 2',
        time: 'Ежедневно: 10:00 - 22:00',
        phone: '+7 (906) 253-62-10'
    },
    {
        lat: 60.033502,
        lng: 30.366165,
        name: 'ТРК Родео Драйв',
        link: '/restaurants/rodeo-draiv',
        address: 'пр-кт Культуры, д 1',
        time: 'Ежедневно: 10:00 - 22:00',
        phone: '+7 (999) 248-95-62',
    },
    {
        lat: 59.945076,
        lng: 30.284436,
        name: 'Василеостровская',
        link: '/restaurants/vasilyevskiy',
        address: 'пр-кт Средний В.О., д 11',
        time: 'Ежедневно: 11:00 - 23:00',
        phone: '+7 (981) 826-76-78',
    },
    {
        lat: 59.930187,
        lng: 30.366479,
        name: 'Невский',
        link: '/restaurants/nevskiy',
        address: 'Невский пр-кт, д 95',
        time: 'Ежедневно: 11:00 - 23:00',
        phone: '+7 (911) 906-07-76',
    },
    {
        lat: 59.924145,
        lng: 30.356211,
        name: 'Лиговский',
        link: '/restaurants/ligovskiy',
        address: 'Лиговский пр-кт, д 87 литера А',
        phone: '+7 (965) 010-19-61',
        time: 'Ежедневно: 11:00 - 23:00',
    },
    {
        lat: 59.819909,
        lng: 30.316982,
        name: 'ТРК Лето',
        link: '/restaurants/leto',
        address: 'Пулковское шоссе, д 25 к 1 литера А',
        time: 'Ежедневно: 10:00 - 22:00',
        phone: '+7 (966) 929-82-02',
    }];

const MapComponent = withGoogleMap((props) =>
    <GoogleMap
        google={props.google}
        zoom={10}
        defaultOptions={{
            fullscreenControl: false,
            disableDefaultUI: true
        }}
        draggable={true}
        center={{lng: 30.3378048, lat: 59.9280011}}
    >
        {points.map(el => {
            return <Marker onClick={() => {
                props.openInfoWindow(el.name)
            }} icon={{url: icon}} key={el.name} position={{lat: el.lat, lng: el.lng}}>
                {props.infoOpen == el.name && <InfoWindow onCloseClick={() => {
                    props.closeInfoWindow()
                }}>
                    <div className="marker-window">
                        <div>
                            <img src={iconIn} alt=""/>
                        </div>
                        <div>
                            <strong>{el.name}</strong>
                            <p>{el.address}</p>
                            <p>{el.phone}</p>
                            <p>График работы: {el.time}</p>
                            <a href={el.link}>Посмотреть меню ресторана</a>
                        </div>
                    </div>

                </InfoWindow>}
            </Marker>
        })}
    </GoogleMap>
);

const MapLandingComponent = withGoogleMap((props) =>
    <GoogleMap
        google={props.google}
        zoom={14}
        defaultOptions={{
            fullscreenControl: false,
            disableDefaultUI: true
        }}
        center={{lng: props.point.lng, lat: props.point.lat}}
    >
        <Marker icon={{url: icon}} key={props.point.name} position={{lat: props.point.lat, lng: props.point.lng}}/>
    </GoogleMap>
);

export const MapLanding = ({height, point}) => {
    return <MapLandingComponent
        point={point}
        // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDBEzz2hUiBvZyBIj4m424Mm6vEGqWzg9s"
        loadingElement={<div style={{height: `100%`}}/>}
        containerElement={<div style={{height: height || "600px"}}/>}
        mapElement={<div style={{height: `100%`}}/>}
    />
}

export const Map = ({height}) => {
    const [active, setActive] = useState(null);

    const closeInfoWindow = () => {
        setActive(null)
    }

    const openInfoWindow = (id) => {
        setActive(id)
    }

    return <MapComponent
        infoOpen={active}
        closeInfoWindow={closeInfoWindow}
        openInfoWindow={openInfoWindow}
        // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDBEzz2hUiBvZyBIj4m424Mm6vEGqWzg9s"
        loadingElement={<div style={{height: `100%`}}/>}
        containerElement={<div style={{height: height || "600px"}}/>}
        mapElement={<div style={{height: `100%`}}/>}
    />
};

