import {useContext} from "react";
import {AppContext} from "../store/context";
import {BottomSheet} from "react-spring-bottom-sheet";
import {Button} from "./Buttons";
import cross from "../static/icons/cross.svg";
import {TitleWGap} from "./Typo";
import g1 from "../static/images/modals/g1.png";
import g2 from "../static/images/modals/g2.png";
import list from "../static/images/modals/list.png";
import radio_active from "../static/icons/radio_active.svg";
import radio from "../static/icons/radio.svg";
import styles from './NewYear.module.css'

export const ResponsiveNewYearModal = () => {
    const {context} = useContext(AppContext);

    const onDissmiss = () => {
        context.modals.newYear.dispatch({type: 'close'});
    };

    return context.settings.isMobile
        ? <BottomSheet open={context.modals.newYear.state.open}
                       onDismiss={onDissmiss}
                       header={<div className="modal-header-clearfix"></div>}
                       footer={<div className={styles.button}>
                           <Button color={'green'} text={'хороших вам выходных!'} onClick={() => onDissmiss()}/>
                       </div>}
                       snapPoints={({maxHeight}) => maxHeight / 1.2}>
            <div className="zone-modal__sheet">
                <NewYearModal/>
            </div>
        </BottomSheet>
        : <div className={`modal ${context.modals.newYear.state.open ? 'modal--open' : ''} zone-modal`}>
            <div className="modal-cross" onClick={() => onDissmiss()}><img src={cross} alt=""/></div>
            <NewYearModal/>
            <div className={styles.button}>
                <Button color={'green'} text={'хороших вам выходных!'} onClick={() => onDissmiss()}/>
            </div>
        </div>
};


const NewYearModal = () => {
    const {context} = useContext(AppContext);

    return <>
        <div className="zone-modal__title">
            <TitleWGap black={'ГРАФИК РАБОТЫ'} green={'В ПРАЗДНИЧНЫЕ ДНИ:'}/>
        </div>
        <div className={styles.inner}>
            <img src={g1} className="zone-modal__inner-image zone-modal__inner-image--g1"/>
            <img src={g2} className="zone-modal__inner-image zone-modal__inner-image--g2"/>
            <img src={list} className="zone-modal__inner-image zone-modal__inner-image--list"/>
            <div className={styles.title}>31 декабря 2022:</div>
            <div className={styles.block}>
                <div className={styles.col1}>закрыто</div>
                <div className={styles.col2}>
                    <div className={styles.row}><strong>Невский</strong> Невский пр-кт, д 95</div>
                    <div className={styles.row}><strong>Лиговский</strong>Лиговский пр-кт, д 87 литера А</div>
                    <div className={styles.row}><strong>Васильевский остров</strong>пр-кт Средний В.О., д 11</div>
                </div>
            </div>
            <div className={styles.block}>
                <div className={styles.col1}>11:00 - 20:00</div>
                <div className={styles.col2}>
                    <div className={styles.row}><strong>ТРК “Меркурий”</strong>ул Савушкина, д 141 литера А</div>
                    <div className={styles.row}><strong>ТРК “Родео Драйв”</strong>пр-кт Культуры, д 1</div>
                    <div className={styles.row}><strong>ТРК “Лето”</strong>Пулковское шоссе, д 25, к 1, литера А</div>
                    <div className={styles.row}><strong>ТРК “Сити Молл”</strong>Коломяжский пр-кт, д 17 к2</div>
                    <div className={styles.row}><strong>ТРК “Европолис Ростокино” (МСК)</strong>пр-т Мира 211</div>
                    <div className={styles.row}><strong>ТРК “Моремолл” (Сочи)</strong>ул.Новая Заря 7</div>
                </div>
            </div>
            <div className={styles.title}>1 января 2023:</div>
            <div className={styles.block}>
                <div className={styles.col1}>закрыто</div>
                <div className={styles.col2}>
                    <div className={styles.row}><strong>Невский</strong> Невский пр-кт, д 95</div>
                    <div className={styles.row}><strong>Лиговский</strong>Лиговский пр-кт, д 87 литера А</div>
                    <div className={styles.row}><strong>Васильевский остров</strong>пр-кт Средний В.О., д 11</div>
                    <div className={styles.row}><strong>ТРК “Родео Драйв”</strong>пр-кт Культуры, д 1</div>
                </div>
            </div>
            <div className={styles.block}>
                <div className={styles.col1}>14:00 - 22:00</div>
                <div className={styles.col2}>
                    <div className={styles.row}><strong>ТРК “Меркурий”</strong>ул Савушкина, д 141 литера А</div>
                    <div className={styles.row}><strong>ТРК “Лето”</strong>Пулковское шоссе, д 25, к 1, литера А</div>
                    <div className={styles.row}><strong>ТРК “Сити Молл”</strong>Коломяжский пр-кт, д 17 к2</div>
                    <div className={styles.row}><strong>ТРК “Европолис Ростокино” (МСК)</strong>пр-т Мира 211</div>
                </div>
            </div>
            <div className={styles.block}>
                <div className={styles.col1}>12:00 - 22:00</div>
                <div className={styles.col2}>
                    <div className={styles.row}><strong>ТРК “Моремолл” (Сочи)</strong>ул.Новая Заря 7</div>
                </div>
            </div>
            <div className={styles.title}>2 января 2023:</div>
            <div className={styles.block}>
            </div>
            <small>Работаем в обычном режиме</small>
        </div>
    </>
};