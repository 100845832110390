import React from "react";
import {ToastContainer} from 'react-toastify';
import {BottomSeo} from '../components/Seo'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import {
    ModalHolder,
    ResponsiveActionModal,
    ResponsiveAuthModal,
    ResponsiveCartModal,
    ResponsivePickupModal,
    ResponsiveProductModal,

} from "../components/Modal";
import {ResponsiveNewYearModal} from '../components/NewYear'
import {usePageViews} from "../App";
import {CartAnimation} from "../components/CartAnimation";

import 'react-toastify/dist/ReactToastify.css';

const Main = ({className, children}) => (
    <div className={className}>{children}</div>
);

const MainLayout = ({children}) => {
    usePageViews();

    return (
        <React.Fragment>
            <Main className="main">
                <CartAnimation/>
                <NavBar/>
                {children}
                <BottomSeo/>
                <Footer/>
                <ModalHolder>
                    <ResponsivePickupModal/>
                    <ResponsiveNewYearModal/>
                    <ResponsiveProductModal/>
                    <ResponsiveAuthModal/>
                    <ResponsiveActionModal/>
                </ModalHolder>
                <ResponsiveCartModal/>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Main>
        </React.Fragment>
    );
};

export default MainLayout;
