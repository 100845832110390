import {animated, useSpring} from 'react-spring'

import banner_pepper1 from '../static/images/parallax/banner/pepper1x4.png'
import banner_pepper2 from '../static/images/parallax/banner/pepper2x4.png'
import banner_spinach1 from '../static/images/parallax/banner/spinach1x4.png'
import banner_spinach2 from '../static/images/parallax/banner/spinach2.png'
import banner_zelen from '../static/images/parallax/banner/zelenx4.png'
import banner_g1 from '../static/images/parallax/banner/g1.png'
import banner_g2 from '../static/images/parallax/banner/g2.png'
import banner_g3 from '../static/images/parallax/banner/g3.png'
import banner_spinach3 from '../static/images/parallax/banner/spinach3x4.png'
import banner_tomat_1 from '../static/images/parallax/banner/tomat_1x4.png'
import banner_tomat_2 from '../static/images/parallax/banner/tomat_2x4.png'

import hits_pepper from '../static/images/parallax/hits/pepperx4.png'
import hits_zelen from '../static/images/parallax/hits/zelenx4.png'

import five_rules_spinach1 from '../static/images/parallax/five_rules/spinach1.png'
import five_rules_spinach2 from '../static/images/parallax/five_rules/spinach2.png'
import five_rules_spinach3 from '../static/images/parallax/five_rules/spinach3x4.png'
import five_rules_pepper from '../static/images/parallax/five_rules/pepperx4.png'
import five_rules_broccoli from '../static/images/parallax/five_rules/broccolix4.png'
import five_rules_carrot from '../static/images/parallax/five_rules/carrotx4.png'
import five_rules_g1 from '../static/images/parallax/five_rules/g1.png'
import five_rules_g2 from '../static/images/parallax/five_rules/g2.png'
import five_rules_g3 from '../static/images/parallax/five_rules/g3.png'
import five_rules_g4 from '../static/images/parallax/five_rules/g4.png'

import testimonials_pepper from '../static/images/parallax/testimonials/pepperx4.png'

import about_g1 from '../static/images/parallax/about/g1.png'
import about_g2 from '../static/images/parallax/about/g2.png'
import about_g3 from '../static/images/parallax/about/g3.png'
import about_spinach from '../static/images/parallax/about/spinachx4.png'
import about_tomato from '../static/images/parallax/about/tomatox4.png'

import faq_pepper from '../static/images/parallax/faq/pepperx4.png'
import faq_spinach1 from '../static/images/parallax/faq/spinach1x4.png'
import faq_spinach2 from '../static/images/parallax/faq/spinach2x4.png'

import arrow_black from '../static/images/about/arrow_black.svg'
import arrow_white from '../static/images/about/arrow_white.svg'

import team_arrow from '../static/images/about/team_arrow.png'
import chief_arrow from '../static/images/about/chief_arrow.png'
import about_ieg from '../static/images/about/ieg.png'
import about_elk from '../static/images/about/elk.png'

import hits_text from '../static/images/parallax/hits/text.png'

import restaurant_text from '../static/images/parallax/restaurant/text.png'

import five_rules_text from '../static/images/rules/text.png'
import five_rules_text_mob from '../static/images/rules/mobile/text.png'
import five_rules_sticks from '../static/images/rules/mobile/sticks.png'
import five_rules_pibimpab from '../static/images/rules/mobile/pibimpab.png'

import useInView from "react-cool-inview";

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;
const trans2 = (x, y) => `translate3d(${x / 8 }px,${y / 8 }px,0)`;
const trans3 = (x, y) => `translate3d(${x / 6 }px,${y / 6 }px,0)`;
const trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`;
const trans5 = (x, y) => `translate3d(${x / 10}px,0,0)`;

export const BannerParallax = ({x, y}) => {
    const [props, set] = useSpring(() => ({xy: [0, 0], config: {mass: 10, tension: 550, friction: 140}}));

    set({xy: calc(x, y)});

    return <div className="parallax">
        <animated.img style={{transform: props.xy.interpolate(trans2)}}
                      className={'parallax-image banner_pepper1'} src={banner_pepper1} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans1)}}
                      className={'parallax-image banner_pepper2'} src={banner_pepper2} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans4)}}
                      className={'parallax-image banner_spinach1'} src={banner_spinach1} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans4)}}
                      className={'parallax-image banner_spinach2'} src={banner_spinach2} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans3)}}
                      className={'parallax-image banner_zelen'} src={banner_zelen} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans1)}}
                      className={'parallax-image banner_g1'} src={banner_g1} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans2)}}
                      className={'parallax-image banner_g2'} src={banner_g2} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans3)}}
                      className={'parallax-image banner_g3'} src={banner_g3} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans2)}}
                      className={'parallax-image banner_tomat_1'} src={banner_tomat_1} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans1)}}
                      className={'parallax-image banner_tomat_2'} src={banner_tomat_2} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans1)}}
                      className={'parallax-image banner_spinach3'} src={banner_spinach3} alt=""/>
    </div>
};

export const HitsParallax = ({x, y}) => {
    const [props, set] = useSpring(() => ({xy: [0, 0], config: {mass: 10, tension: 550, friction: 140}}));

    set({xy: calc(x, y)});

    return <div className="parallax">
        <animated.img style={{transform: props.xy.interpolate(trans2)}} className={'parallax-image hits_zelen'} src={hits_zelen} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans1)}} className={'parallax-image hits_pepper'} src={hits_pepper} alt=""/>
        <img className={'static-image hits_text'} src={hits_text} alt=""/>
    </div>
};

export const FiveRulesParallax = ({x, y}) => {
    const [props, set] = useSpring(() => ({xy: [0, 0], config: {mass: 10, tension: 550, friction: 140}}));

    set({xy: calc(x, y)});

    return <div className="parallax">
        <animated.img style={{transform: props.xy.interpolate(trans3)}}
                      className={'parallax-image five_rules_spinach1'} src={five_rules_spinach1} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans2)}}
                      className={'parallax-image five_rules_spinach2'} src={five_rules_spinach2} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans3)}}
                      className={'parallax-image five_rules_spinach3'} src={five_rules_spinach3} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans1)}}
                      className={'parallax-image five_rules_pepper'} src={five_rules_pepper} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans4)}}
                      className={'parallax-image five_rules_broccoli'} src={five_rules_broccoli} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans2)}}
                      className={'parallax-image five_rules_carrot'} src={five_rules_carrot} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans4)}}
                      className={'parallax-image five_rules_g1'} src={five_rules_g1} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans3)}}
                      className={'parallax-image five_rules_g2'} src={five_rules_g2} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans2)}}
                      className={'parallax-image five_rules_g3'} src={five_rules_g3} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans1)}}
                      className={'parallax-image five_rules_g4'} src={five_rules_g4} alt=""/>
        <img className={'static-image five_rules_text'} src={five_rules_text} alt=""/>
    </div>
};

export const AboutParallax = ({x, y}) => {
    const [props, set] = useSpring(() => ({xy: [0, 0], config: {mass: 10, tension: 550, friction: 140}}));

    set({xy: calc(x, y)});

    return <div className="parallax">
        <animated.img style={{transform: props.xy.interpolate(trans1)}}
                      className={'parallax-image about_tomato'} src={about_tomato} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans5)}}
                      className={'parallax-image about_spinach'} src={about_spinach} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans1)}}
                      className={'parallax-image about_g1'} src={about_g1} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans2)}}
                      className={'parallax-image about_g2'} src={about_g2} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans3)}}
                      className={'parallax-image about_g3'} src={about_g3} alt=""/>
    </div>
};

export const AboutStaticDesktop = () => {
    return <div className={`parallax`}>
        <img className={'static-image about_elk'} src={about_elk} alt=""/>
        <img className={'static-image about_ieg'} src={about_ieg} alt=""/>
        <ChefArrow/>
        <TeamArrow/>
    </div>
}

const TeamArrow = () => {
    const {observe, inView} = useInView({
        unobserveOnEnter: true,
        rootMargin: "-300px 0px",
    });

    return <div ref={observe} className={`static-image ${inView ? 'active' : ''}`}>
        <span className={'static-image about_team_text text--handwritten'}>Команда</span>
        <img className={'static-image about_team_arrow'} src={arrow_black} alt=""/>
    </div>
}

const ChefArrow = () => {
    const {observe, inView} = useInView({
        unobserveOnEnter: true,
        rootMargin: "-300px 0px",
    });

    return <div ref={observe} className={`static-image ${inView ? 'active' : ''}`}>
        <span className={'static-image about_chief_text text--handwritten'}>Шеф</span>
        <img className={'static-image about_chief_arrow'} src={arrow_white} alt=""/>
    </div>
}

export const TestimonialsParallax = ({x, y}) => {
    const [props, set] = useSpring(() => ({xy: [0, 0], config: {mass: 10, tension: 550, friction: 140}}));

    set({xy: calc(x, y)});

    return <div className="parallax">
        <animated.img style={{transform: props.xy.interpolate(trans1)}}
                      className={'parallax-image testimonials_pepper'} src={testimonials_pepper} alt=""/>
    </div>
};

export const FaqParallax = ({x, y}) => {
    const [props, set] = useSpring(() => ({xy: [0, 0], config: {mass: 10, tension: 550, friction: 140}}));


    set({xy: calc(x, y)});

    return <div className="parallax">
        <animated.img style={{transform: props.xy.interpolate(trans3)}}
                      className={'parallax-image faq_spinach1'} src={faq_spinach1} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans1)}}
                      className={'parallax-image faq_spinach2'} src={faq_spinach2} alt=""/>
        <animated.img style={{transform: props.xy.interpolate(trans2)}}
                      className={'parallax-image faq_pepper'} src={faq_pepper} alt=""/>

    </div>
};

// Static for mobile

export const BannerStatic = () => {
    return <div className="static-images">
        <img className={'static-image banner_pepper1'} src={banner_pepper1} alt=""/>
        <img className={'static-image banner_pepper2'} src={banner_pepper2} alt=""/>
        <img className={'static-image banner_spinach1'} src={banner_spinach1} alt=""/>
        <img className={'static-image banner_spinach2'} src={banner_spinach2} alt=""/>
        <img className={'static-image banner_zelen'} src={banner_zelen} alt=""/>
        <img className={'static-image banner_g1'} src={banner_g1} alt=""/>
        <img className={'static-image banner_g2'} src={banner_g2} alt=""/>
        <img className={'static-image banner_g3'} src={banner_g3} alt=""/>
        <img className={'static-image banner_tomat_1'} src={banner_tomat_1} alt=""/>
        <img className={'static-image banner_tomat_2'} src={banner_tomat_2} alt=""/>
        <img className={'static-image banner_spinach3'} src={banner_spinach3} alt=""/>
    </div>
};

export const HitsStatic = () => {
    return <div className="static-images">
        <img className={'static-image hits_zelen'} src={hits_zelen} alt=""/>
        <img className={'static-image hits_pepper'} src={hits_pepper} alt=""/>
        <img className={'static-image hits_text'} src={hits_text} alt=""/>
    </div>
};

export const FiveRulesStatic = () => {
    return <div className="static-images">
        <img className={'static-image five_rules_spinach1'} src={five_rules_spinach1} alt=""/>
        <img className={'static-image five_rules_spinach2'} src={five_rules_spinach2} alt=""/>
        <img className={'static-image five_rules_spinach3'} src={five_rules_spinach3} alt=""/>
        <img className={'static-image five_rules_pepper'} src={five_rules_pepper} alt=""/>
        <img className={'static-image five_rules_g2'} src={five_rules_g2} alt=""/>

        <img className={'static-image five_rules_sticks'} src={five_rules_sticks} alt=""/>
        <img className={'static-image five_rules_text'} src={five_rules_text_mob} alt=""/>
        <img className={'static-image five_rules_pibimpab'} src={five_rules_pibimpab} alt=""/>
    </div>
};

export const AboutStatic = () => {
    return <div className="static-images">
        <img className={'static-image about_tomato'} src={about_tomato} alt=""/>
        <img className={'static-image about_spinach'} src={about_spinach} alt=""/>
        <img className={'static-image about_g1'} src={about_g1} alt=""/>
        <img className={'static-image about_g2'} src={about_g2} alt=""/>
        <img className={'static-image about_g3'} src={about_g3} alt=""/>
        <img className={'static-image about_g3'} src={about_g3} alt=""/>
        <img className={'static-image about_elk'} src={about_elk} alt=""/>
        <img className={'static-image about_ieg'} src={about_ieg} alt=""/>
        <img className={'static-image about_team_arrow'} src={team_arrow} alt=""/>
        <img className={'static-image about_chief_arrow'} src={chief_arrow} alt=""/>
        <span className={'static-image about_chief_text text--handwritten'}>Шеф</span>
        <span className={'static-image about_team_text text--handwritten'}>Команда</span>
    </div>
};

export const RestaurantsStatic = () => {
    return <div className="static-images">
        <img className={'static-image restaurants_text'} src={restaurant_text} alt=""/>
    </div>
};

export const TestimonialsStatic = () => {
    return <div className="static-images">
        <img className={'static-image testimonials_pepper'} src={testimonials_pepper} alt=""/>
    </div>
};

export const FaqStatic = () => {
    return <div className="static-images">
        <img className={'static-image faq_spinach1'} src={faq_spinach1} alt=""/>
        <img className={'static-image faq_spinach2'} src={faq_spinach2} alt=""/>
        <img className={'static-image faq_pepper'} src={faq_pepper} alt=""/>
    </div>
};