import {useContext, useEffect, useState} from 'react'
import {AppContext} from "../store/context";

export const CartAnimation = () => {
    const {context} = useContext(AppContext);

    const [active, setActive] = useState(false);

    const [x, setX] = useState(0);
    const [y, setY] = useState(0);

    useEffect(() => {
        if (context.cart.state.bubble.x !== 0 && context.cart.state.bubble.y !== 0) {
            setX(window.innerWidth - context.cart.state.bubble.x);
            setY(context.cart.state.bubble.y);
            setActive(true);

            setTimeout(() => {
                setX(0);
                setY(0);
                setActive(false);
                context.cart.dispatch({type: 'setBubblePos', payload: {x: 0, y: 0}})
            }, 10)
        }

    }, [context.cart.state.bubble.x, context.cart.state.bubble.y]);

    return <div
        style={{top: y, right: x}}
        className={`bubble ${active ? 'bubble-active' : 'bubble-hide'}`}/>
};