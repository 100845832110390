import {useState, useEffect} from 'react'

export const Timer = ({ seconds, callback, retryCallback }) => {
    const [timeLeft, setTimeLeft] = useState(seconds);

    useEffect(() => {
        if (!timeLeft) {
            return;
        }
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    return <div className={'countdown'}>
        {timeLeft
            ? <span>Отправить повторно через {timeLeft} секунд</span>
            : <span>Отправить код</span>}
    </div>
};
