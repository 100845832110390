import React, {useContext, useState} from 'react'
import {Link} from 'react-router-dom'

import {AppContext} from "../store/context";
import {Loader, LoaderFullsize} from "../components/Loader";
import {TitleWGap} from "../components/Typo";
import {RestaurantSlider} from '../components/restaurant/Slider'
import {ProductCardSlider} from "../components/product/Card";
import {MainBanner} from "../components/sections/MainBanner";
import {ActionList} from "../components/action/Action";
import {TestimonialSlider} from '../components/testimonials/Slider'
import {Accordeon} from "../components/Accordeon";
import {FeedbackForm} from "../components/Form";
import {FiveRulesBanner} from '../components/elements/FiveRules'
import {
    AboutParallax,
    AboutStatic,
    AboutStaticDesktop,
    BannerParallax,
    BannerStatic,
    FaqParallax,
    FaqStatic,
    FiveRulesParallax,
    FiveRulesStatic,
    HitsParallax,
    HitsStatic,
    RestaurantsStatic,
    TestimonialsParallax,
    TestimonialsStatic
} from "../components/Parallax";

import five from "../static/icons/five.svg"
import arrow_right_long from "../static/icons/arrow_right_long.svg"
import chef from "../static/images/about/chief_desktop.webp"
import team from "../static/images/about/team.png"

import chef_mob from "../static/images/about/chief_mob.webp"
import team_mob from "../static/images/about/team_mob_main.png"


export class Main extends React.Component {
    constructor(props, context) {
        super();

        this.processor = context.context.processor;

        this.state = {
            loaded: false,
            products: [],
            actions: [],
            testimonials: [],
            restaurants: [],
            faq: [],
            coords: {
                x: 0,
                y: 0
            }
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.processor.screen.getScreen('main').then(response => {
            this.setState({
                loaded: true,
                products: response.products,
                actions: response.actions,
                testimonials: response.testimonials,
                restaurants: response.restaurants,
                faq: response.faq,
            });

            return response;
        }).then(response => {
            this.context.context.modals.action.dispatch({type: 'setList', payload: response.actions})
        })
    }

    loader() {
        return this.context.context.settings.isMobile ? <Loader/> : <LoaderFullsize/>
    }

    render() {

        return this.state.loaded ? <>
            <SectionBanner/>
            <SectionHits products={this.state.products}/>
            <SectionRules/>
            <SectionActions actions={this.state.actions}/>
            <SectionRestaurants restaurants={this.state.restaurants}/>
            <SectionTestimonials testimonials={this.state.testimonials}/>
            <SectionAbout/>
            <SectionFAQ faq={this.state.faq}/>
        </> : this.loader()
    }
}

const SectionBanner = () => {
    const {context} = useContext(AppContext);

    const [x, setX] = useState(500);
    const [y, setY] = useState(500);

    return <div className='container main-banner parallax-aware' onMouseMove={({clientX: x, clientY: y}) => {
        setX(x);
        setY(y)
    }}>
        <MainBanner/>
        {context.settings.isMobile
            ? <BannerStatic/>
            : <BannerParallax x={x} y={y}/>
        }
    </div>
};

const SectionHits = ({products}) => {
    const {context} = useContext(AppContext);

    const [x, setX] = useState(500);
    const [y, setY] = useState(500);

    return <div className='container hits parallax-aware' onMouseMove={({clientX: x, clientY: y}) => {
        setX(x);
        setY(y)
    }}>
        <TitleWGap black={"Это стоит попробовать"} green={"MUST TRY"}/>
        <ProductCardSlider products={products}/>
        <div className="hits-link">
            <Link to={'/menu?openAddress=1'}>
                <div className="text">Все меню</div>
                <div className="icon arrow">
                    <img src={arrow_right_long} alt=""/>
                </div>
            </Link>
        </div>
        {context.settings.isMobile
            ? <HitsStatic/>
            : <HitsParallax x={x} y={y}/>
        }
    </div>
};

const SectionRules = () => {
    const {context} = useContext(AppContext);

    const [x, setX] = useState(500);
    const [y, setY] = useState(500);

    return <div className="container five-rules parallax-aware" onMouseMove={({clientX: x, clientY: y}) => {
        setX(x);
        setY(y)
    }}>
        <div className="title-holder">
            <div className="number"><img src={five} alt=""/></div>
            <div className="title">
                <span className='color-black'>{'ПРАВИЛ'}</span><br/>
                <span className='color-green'>{'ЗДОРОВОГО'}</span><br/>
                <span className='color-green'>{'ПИТАНИЯ'}</span>
            </div>
        </div>
        <FiveRulesBanner/>
        {context.settings.isMobile
            ? <FiveRulesStatic/>
            : <FiveRulesParallax x={x} y={y}/>
        }
    </div>
};

const SectionActions = ({actions}) => {
    return <div className="container actions">
        <TitleWGap black={'акции и'} green={'предложения'}/>
        <ActionList actions={actions}/>
    </div>
};

const SectionRestaurants = ({restaurants}) => {
    return <>
        <div className="container restaurants parallax-aware">
            <div className="title-holder">
                <div className="title">
                    <span className='color-black'>{'наши'}</span><br/>
                    <span className='color-black'>{'рестораны '}</span>
                    <span className='color-green'>{'Koreana light'}</span>
                </div>
            </div>
            <RestaurantsStatic/>
        </div>
        <div className="container-fluid">
            <RestaurantSlider restaurants={restaurants}/>
        </div>
    </>
};

const SectionTestimonials = ({testimonials}) => {
    const {context} = useContext(AppContext);

    const [x, setX] = useState(500);
    const [y, setY] = useState(500);

    return <div className="container testimonials parallax-aware" onMouseMove={({clientX: x, clientY: y}) => {
        setX(x);
        setY(y)
    }}>
        <div className="testimonials__description">
            <div className="title-holder">
                <div className="title">
                    <span className='color-black'>{'отзывы'}</span><br/>
                    <span className='color-green'>{'наших гостей'}</span>
                </div>
            </div>
        </div>
        <div className="testimonials__slider-holder">
            <TestimonialSlider testimonials={testimonials}/>
        </div>
        {context.settings.isMobile
            ? <TestimonialsStatic/>
            : <TestimonialsParallax x={x} y={y}/>
        }
    </div>
};

const SectionAbout = () => {
    const {context} = useContext(AppContext);

    const desktop = () => {
        return <>
            <AboutStaticDesktop/>
            <div className="about-images">
                <div className="about-images__image about-images__image--down">
                    <img src={team} alt=""/>
                </div>
                <div className="about-images__image about-images__image--up">
                    <img src={chef} alt=""/>
                </div>
            </div>
            <div className="about-disclamer">
                <div className="about-disclamer__text text">Мы верим, что питание - это самый главный
                    фактор, определяющий здоровье человека, качество и продолжительность его жизни.
                </div>
                {/*<Link to={"/about"} className="about-disclamer__link text text--faded">Подробнее</Link>*/}
            </div>
        </>
    };

    const mobile = () => {
        return <>
            <div className="about-images__image about-images__image--up">
                <img src={chef_mob} alt=""/>
            </div>
            <div className="about-disclamer">
                <div className="about-disclamer__text text">Мы верим, что питание - это самый главный
                    фактор, определяющий здоровье человека, качество и продолжительность его жизни.
                </div>
                {/*<Link to={"/about"} className="about-disclamer__link text text--faded">Подробнее</Link>*/}
            </div>
            <div className="about-images__image about-images__image--down">
                <img src={team_mob} alt=""/>
            </div>
        </>
    };

    const [x, setX] = useState(500);
    const [y, setY] = useState(500);

    return <div className="container-fluid about parallax-aware" onMouseMove={({clientX: x, clientY: y}) => {
        setX(x);
        setY(y)
    }}>
        <div className="container">
            <div className="about-holder">
                <div className="about-title">
                    <div className="title-holder">
                        <div className="title">
                            <span className='color-white'>{'о нас'}</span>
                        </div>
                    </div>
                    <div className="about-summary">
                        Мы решительно не согласны с тем, что здоровое питание это скучно, дорого и не всегда
                        вкусно.
                    </div>
                </div>
                {context.settings.isMobile ? mobile() : desktop()}
            </div>
        </div>
        {context.settings.isMobile
            ? <AboutStatic/>
            : <AboutParallax x={x} y={y}/>
        }
    </div>
};

const SectionFAQ = ({faq}) => {
    const {context} = useContext(AppContext);

    const [x, setX] = useState(500);
    const [y, setY] = useState(500);

    return <div className="container faq parallax-aware" onMouseMove={({clientX: x, clientY: y}) => {
        setX(x);
        setY(y)
    }}>
        <div className="faq-holder">
            <div className="faq-list">
                <div className="title-holder">
                    <div className="title">
                        <span className='color-black'>{'вопрос/'}</span>
                        <span className='color-green'>{'ответ'}</span>
                    </div>
                </div>
                <div className="accordeon faq-accordeon">
                    {faq.map(el => {
                        return <Accordeon title={el.question} inner={el.answer}/>
                    })}
                </div>
            </div>
            <div className="faq-feedback">
                <FeedbackForm/>
            </div>
        </div>
        {context.settings.isMobile
            ? <FaqStatic/>
            : <FaqParallax x={x} y={y}/>
        }
    </div>
};

Main.contextType = AppContext;