import React, {useContext, useState} from 'react'
import Slider from "react-slick";
import {Link, useHistory} from 'react-router-dom';

import {RestaurantSimpleDropdown} from "../Dropdown";

import arrow_left from "../../static/icons/arrow_left.svg"
import arrow_right from "../../static/icons/arrow_right.svg"
import {STATIC_HOST} from "../../const";
import {AppContext} from "../../store/context";

export class RestaurantSlider extends React.Component {
    constructor(props) {
        super(props);

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);

        this.state = {
            restaurants: props.restaurants,
            selected: null,
            single: props.single || false
        }
    }

    componentDidMount() {
        this.setState({
            selected: this.state.restaurants[0] || null
        });
    }

    next() {
        this.slider.slickNext();
        return
        const list = this.state.restaurants;
        if (!list) {
            return
        }
        const length = list.length;

        if (length <= 1) {
            return;
        }

        const currentElementIndex = list.findIndex((element, index, array) => {
            return element.id === this.state.selected.id
        });

        let nextElement = null;

        if (currentElementIndex === (length - 1)) {
            nextElement = list[0]
        } else {
            nextElement = list[currentElementIndex + 1]
        }

        this.setState({
            selected: nextElement
        })
    }

    previous() {
        this.slider.slickPrev();
        return
        const list = this.state.restaurants;
        const length = list.length;

        if (length <= 1) {
            return;
        }

        const currentElementIndex = list.findIndex((element, index, array) => {
            return element.id === this.state.selected.id
        });

        let prevElement = null;

        if (currentElementIndex === 0) {
            prevElement = list[length - 1]
        } else {
            prevElement = list[currentElementIndex - 1]
        }

        this.setState({
            selected: prevElement
        })
    }

    mobile() {
        const settings = {
            slidesToShow: 1,
            speed: 500,
            arrows: false,
            draggable: false
        };

        return <div className="container">
            {this.state.selected ?
                <div className="restaurant-slider">
                    {!this.state.single ?
                        <div className="restaurant-slider-selector">
                            <RestaurantSimpleDropdown name={'Выбрать ресторан'}
                                                      selected={this.state.selected}
                                                      values={this.state.restaurants}
                                                      callback={(item) => {
                                                          this.setState({selected: item})
                                                      }}/>
                        </div> : null}
                    <div className="restaurant-slider__holder">
                        <Slider ref={c => (this.slider = c)} {...settings}>
                            {this.state.selected.images.map(el => {
                                return <div className="slide">
                                    <div className="restraurant-slider__item animate__animated animate__zoomIn"
                                         style={{backgroundImage: `url('${STATIC_HOST + el.source}')`}}>
                                    </div>
                                </div>
                            })}
                        </Slider>
                    </div>
                    <div className='restaurant-slider-arrows'>
                        <div className="slider-arrow" onClick={this.previous}>
                            <img src={arrow_left} alt=""/>
                        </div>
                        <div className="slider-arrow" onClick={this.next}>
                            <img src={arrow_right} alt=""/>
                        </div>
                    </div>
                </div> : null}
        </div>
    }

    desktop() {
        const settings = {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '13%',
            speed: 1,
            arrows: false,
            draggable: false
        };
        return this.state.selected ? <div className="restaurant-slider">
            <div className="restaurant-slider__holder">
                {!this.state.single ? <RestaurantSliderOverlay restaurant={this.state.selected}/> : null}
                <Slider ref={c => (this.slider = c)} {...settings}>
                    {this.state.selected.images.map(el => {
                        return <div className="slide">
                            <div className="restraurant-slider__item animate__animated animate__zoomIn"
                                 style={{backgroundImage: `url('${STATIC_HOST + el.source}')`}}>
                            </div>
                        </div>
                    })}
                </Slider>
            </div>
            {!this.state.single ?
                <div className="restaurant-slider-selector">
                    <RestaurantSimpleDropdown name={'Выбрать ресторан'}
                                              selected={this.state.selected}
                                              values={this.state.restaurants}
                                              callback={(item) => {
                                                  this.setState({selected: item})
                                              }}/>
                </div> : null}
            <div className='restaurant-slider-arrows'>
                <div className="slider-arrow" onClick={this.previous}>
                    <img src={arrow_left} alt=""/>
                </div>
                <div className="slider-arrow" onClick={this.next}>
                    <img src={arrow_right} alt=""/>
                </div>
            </div>
        </div> : null
    }

    render() {
        return this.context.context.settings.isMobile
            ? this.mobile()
            : this.desktop()
    }
}

export const RestaurantImagesSlider = ({restaurant}) => {
    const {context} = useContext(AppContext);

    const [translate, setTranslate] = useState(0);
    const [index, setIndex] = useState(0);

    const shift = context.settings.isMobile ? 333 : 338;

    const images = restaurant.images;

    const previous = () => {
        const prevIndex = index - 1;

        if (prevIndex < 0) {
            const lastIndex = images.length - 1;

            setIndex(lastIndex);
            setTranslate((lastIndex) * -shift);
            return;
        }

        setTranslate(translate + shift);
        setIndex(prevIndex)
    };

    const next = () => {
        const nextIndex = index + 1;

        if (nextIndex >= images.length) {
            setIndex(0);
            setTranslate(0);
            return;
        }

        setTranslate(translate - shift);
        setIndex(nextIndex)
    };


    return <div className="restaurant-images">
        <div className="restaurant-images__holder">
            <div className="restaurant-images__slider">
                {images.map((el, i) => {
                    return <div style={{transform: `translate(${translate}px, ${0}px)`}}
                                className={`slide ${i === index ? 'slide-active' : ''}`}>
                        <div className="restaurant-images__slider-item"
                             style={{backgroundImage: `url('${STATIC_HOST + el.source}')`}}>
                        </div>
                    </div>
                })}
            </div>
        </div>
        <div className='restaurant-images__arrows'>
            <div className="restaurant-images__arrow slider-arrow" onClick={previous}>
                <img src={arrow_left} alt=""/>
            </div>
            <div className="restaurant-images__arrow slider-arrow" onClick={next}>
                <img src={arrow_right} alt=""/>
            </div>
        </div>
    </div>
}

const RestaurantSliderOverlay = ({restaurant}) => {
    const history = useHistory();

    return <div className="restaurant-slider__overlay" onClick={() =>{history.push(`/restaurants/${restaurant.slug}`)}}>
        <div className="restaurant-slider__description">
            <div className="restaurant-slider__description-text">{restaurant.title}</div>
            <div className="restaurant-slider__description-address">{restaurant.address}</div>
            <Link className="restaurant-slider__description-link"
                  to={`/restaurants/${restaurant.slug}`}>подробнее</Link>
        </div>
    </div>
}

RestaurantSlider.contextType = AppContext;