import React from 'react'
import Collapse from "react-collapse";

import {AppContext} from "../../store/context";

import pibim from '../../static/images/landings/moscow/pibim.png'
import sec1 from '../../static/images/landings/moscow/sec1.png'
import sec2 from '../../static/images/landings/moscow/sec2.png'
import sec3 from '../../static/images/landings/moscow/sec3.png'
import hit1 from '../../static/images/landings/moscow/hit1.png'
import hit2 from '../../static/images/landings/moscow/hit2.png'
import arrow from '../../static/images/landings/moscow/arrow.svg'
import moscow from '../../static/images/landings/moscow/moscow.png'
import green from '../../static/images/landings/moscow/green.png'

import leaf from '../../static/images/landings/moscow/mobleaf.png'
import pepp from '../../static/images/landings/moscow/mobpepper.png'
import pib from '../../static/images/landings/moscow/mobpib.png'

import styles from './Moscow.module.css'
import {Link} from "react-router-dom";

import {MapLanding} from "../../components/Map"

export class Moscow extends React.Component {
    constructor() {
        super();
        this.state = {
            active: 0,
            showText: false,
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    render() {
        return <div className="about-page reveal">
            <div className="container container-pd">
                <div className={styles.intro}>
                    <h1 className={styles.title}>
                        <span className='color-black'>{"кореана light"}</span><br/>
                        <span className='color-black'>{"в москве"}</span>
                    </h1>
                    <div className={styles.introText}>
                        <p>
                            Корейская уличная еда в Москве? Возможно ли это? В московском торгово-развлекательном
                            комплексе «Европолис», что недалеко от станции метро «Ростокино» на проспекте Мира, 211
                            (корпус 2) на 2-м этаже гастрономической зоны «Ситифуд» уютно расположился островок Южной
                            Кореи — стрит-фуд ресторан Koreana Light.
                            <br/><br/>
                            Динамичный и молодежный формат ресторанов Кореана Light состоит из обилия вкусов,
                            приветливости персонала и невероятной теплоты атмосферы.
                        </p>
                    </div>
                    <a className={styles.introButton} href={"https://koreanalight.ru/files/moscow_menu.pdf"}
                       target={"_blank"}>
                        <span>меню стрит-фуда в москве</span>
                        <span>pdf</span>
                    </a>
                </div>
            </div>
            {this.context.context.settings.isMobile ? <div className={styles.mobImages}>
                <img src={pepp} alt=""/>
                <img src={leaf} alt=""/>
                <img src={pib} alt=""/>
            </div> : null}
            <div className="container container-pd">
                <div className={styles.map}>
                    <div className={styles.mapComponent}>
                        <MapLanding height={this.context.context.settings.isMobile ? "400px" : "300px"} point={{
                            lat: 55.845472,
                            lng: 37.661027,
                            name: 'ТРК Европолис Ростокино (Москва)',
                        }}/>
                    </div>
                    <div className={`restaurant-contact restaurant-contact--show restaurant-contact--no-back`}>
                        <div className="restaurant-contact__title">
                            ТРК Европолис Ростокино (Москва)
                        </div>
                        <div className="restaurant-contact__address text">Москва, пр-т Мира 211</div>
                        <div className="restaurant-contact__phone text"><a href={`tel:+7 916 842 95 01`}>+7 916 842 95
                            01</a></div>
                        <div className="restaurant-contact__operating text">
                            График работы: 10:00 - 22:00
                        </div>
                        <div className="restaurant-contact__link text">
                            <a target="_blank" href={"https://goo.gl/maps/qqVR5DCsLuPgd2Bt6"}>Открыть в Google Maps</a>
                        </div>
                    </div>
                </div>
                <div className={styles.about}>
                    <h2 className={styles.title}>
                        <span className='color-black'>{"Корейский стрит-фуд акцент на"}</span><br/>
                        <span className='color-green'>{"вкусной и здоровой еде"}</span>
                    </h2>
                    <div className={styles.aboutTexts}>
                        <div className={styles.text}>
                            <p>Цены в Koreana Light приятные, порции сытные, а ингредиенты
                                полезные. Мы следуем пяти
                                принципам здорового питания и делаем акцент на правильном сочетании белков, жиров и
                                углеводов, отсутствии консервантов и стабилизаторов, большом количестве растительных
                                продуктов, богатых витаминами и микроэлементами, ярких вкусах и бережной технологии
                                приготовления. </p>
                        </div>
                        {this.context.context.settings.isMobile ?
                            <>
                                <Collapse isOpened={this.state.showText} theme={{collapse: styles.collapse}}>
                                    <div className={styles.text}>
                                        И таким образом создаем блюда, помогающие
                                        поддерживать здоровье, бодрость
                                        духа и хорошее настроение.
                                        <br/><br/>
                                        Мы стремимся к тому, чтобы даже в большом городе с его суетой,
                                        ненормированным графиком и быстрым темпом, все гости Koreana Light получали
                                        качественную полезную еду и море удовольствия.
                                    </div>
                                </Collapse>
                                <div className={styles.aboutShow}
                                     onClick={() => this.setState({showText: !this.state.showText})}>
                                    {this.state.showText ? "Скрыть" : "Раскрыть"}
                                </div>
                            </>
                            :
                            <div className={styles.text}>
                                <p>
                                    И таким образом создаем блюда, помогающие поддерживать здоровье, бодрость духа и
                                    хорошее
                                    настроение.
                                    <br/><br/>
                                    Мы стремимся к тому, чтобы даже в большом городе с его суетой, ненормированным
                                    графиком
                                    и быстрым темпом, все гости Koreana Light получали качественную полезную еду и море
                                    удовольствия.
                                </p>
                            </div>}
                    </div>
                </div>
                <div className={styles.menu}>
                    <div className={styles.menuImage}>
                        <img src={pibim} alt="Пибимпабы в корейском стрит фуде Кореана Лайт в Москве"/>
                    </div>
                    <div className={styles.menuTexts}>
                        <h2 className={styles.title}>
                            <span className='color-black'>{"Меню корейского стрит-фуда"}</span><br/>
                            <span className='color-green'>{"кореана light"}</span>
                        </h2>
                        <div className={styles.text}>
                            <p>
                                Почему уличная корейская еда становится популярнее день ото дня? Может быть потому, что
                                в наших любимых дорамах её едят на каждом шагу? А может быть потому, что почти каждое
                                блюдо объединяет все вкусы — соленое, кислое, сладкое, острое — и даже больше? Или
                                потому, что корейская уличная еда основана на правильном сочетании белков, жиров и
                                углеводов без консервантов, стабилизаторов и искусственных ароматизаторов?
                                <br/><br/>
                                Это всё так. Но эта еда также имеет свою собственную эстетику, которая проявляется не
                                только в ее вкусе, но и в ее внешнем виде и подаче.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.greenSections}>
                <div className={styles.greenSection}>
                    <div className={styles.greenSectionText}>
                        <h3 className={styles.titleSmall}>
                            <span className='color-black'>{"Блюда корейской уличной еды в "}</span>
                            <span className='color-green'>{"koreana light"}</span>
                        </h3>
                        <div className={styles.text}>
                            <p>Среди самых популярных блюд корейской уличной еды можно назвать токпокки (рисовые
                                палочки в остром соусе), кимпаб (корейские роллы с начинкой), онигири (закуска из
                                риса с начинкой), моти и паровые пирожки с различными начинками — пегодя. И все это
                                есть в нашем меню.</p>
                        </div>
                    </div>
                    <img src={sec1}
                         alt="Пегодя, кимпабы, токпокки в ресторане корейской уличной еды в Москве Кореана light"/>
                </div>
                <div className={styles.greenSectionReverse}>
                    <img src={sec2} alt="Корейская стеклянная лапша чапче стрит фуд кафе Koreana light в Москве"/>
                    <div className={styles.greenSectionText}>
                        <h3 className={styles.titleSmall}>
                            <span className='color-black'>{"Традиционные корейские блюда в "}</span>
                            <span className='color-green'>{"koreana light"}</span>
                        </h3>
                        <div className={styles.text}>
                            <p>Помимо блюд корейской уличной еды, у нас также можно заказать и традиционные блюда
                                корейской кухни — куксу, пибимпаб, пульгоги, чапче. </p>
                        </div>
                    </div>

                </div>
                <div className={styles.greenSection}>
                    <div className={styles.greenSectionText}>
                        <h3 className={styles.titleSmall}>
                            <span className='color-black'>{"корейские дошираки в "}</span>
                            <span className='color-green'>{"koreana light"}</span>
                        </h3>
                        <div className={styles.text}>
                            Особенный пункт меню — доширак. Нет, это не лапша быстрого приготовления, как вы могли
                            подумать. А полноценный комплексный обед, чаще всего он включает в себя набор из
                            корейских салатов — панчан-сет, суп, горячее и закуски. В Koreana Light есть несколько
                            видов <Link to={"/menu/doshiraki"}>дошираков</Link>, можно выбрать по вкусу: более или менее острый, с курицей или
                            говядиной.
                        </div>
                    </div>
                    <img src={sec3}
                         alt="Комплексный обед доширак в корейском стрит фуд кафе Кореана Лайт в Москве"/>
                </div>
            </div>
            <div className={styles.hits}>
                <h3 className={styles.title}>
                    <span className='color-black'>{"Абсолютные хиты корейского стрит-фуда "}</span>
                    <span className='color-green'>{"koreana Light"}</span>
                </h3>
                <div className={styles.hitsImages}>
                    <img src={hit1} alt="Пибимпаб с говядиной с Москве корейское кафе уличной еды Koreana Light"/>
                    <img src={hit2} alt="Бургер ким из риса в стрит-фуде корейской кухни Кореана Light в Москве"/>
                </div>
                <div className={styles.hitsMenus}>
                    {this.context.context.settings.isMobile ? <img src={leaf} alt=""/> : null}
                    <div className={styles.hitMenu}>
                        <div className={styles.hitMenuHead}>
                            <div className={styles.hitMenuTitle} onClick={() => this.setState({active: 0})}>
                                <span>Пибимпабы</span>
                                <img src={arrow} alt=""/>
                            </div>
                            {this.context.context.settings.isMobile ? null :
                                <Link to={"menu/bluda-s-risom"} className={styles.hitMenuButton}>
                                    меню пибимпабы
                                </Link>}
                        </div>
                        <Collapse isOpened={this.state.active === 0} theme={{collapse: styles.collapse}}>
                            <div className={styles.hitMenuText}>
                                Первое место заслуженно занимает его величество пибимпаб. Это корейское блюдо из риса,
                                овощей, мяса и яиц. Оно и выглядит прекрасно, и на вкус безупречно. Пибимпаб — это не
                                только вкусно, но и полезно, так как блюдо содержит много клетчатки, сложных углеводов и
                                белков. Во всем мире пибимпаб считается одной из визитных карточек Кореи и даже
                                популярнее, чем группы K-pop.
                            </div>
                            {!this.context.context.settings.isMobile ? null :
                                <Link to={"menu/bluda-s-risom"} className={styles.hitMenuButton}>
                                    меню пибимпабы
                                </Link>}
                        </Collapse>
                    </div>
                    <div className={styles.hitMenu}>
                        <div className={styles.hitMenuHead}>
                            <div className={styles.hitMenuTitle} onClick={() => this.setState({active: 1})}>
                                <span>БУРГЕР КИМ</span>
                                <img src={arrow} alt=""/>
                            </div>
                            {this.context.context.settings.isMobile ? null :
                                <Link to={"menu/byrger-kim"} className={styles.hitMenuButton}>
                                    меню бургеры ким
                                </Link>}
                        </div>
                        <Collapse isOpened={this.state.active === 1} theme={{collapse: styles.collapse}}>
                            <div className={styles.hitMenuText}>
                                Бургеры от шеф-повара Koreana Light — это настоящий переворот в мире фастфуда! Забудьте
                                о привычных булочках — <Link to={"/menu/byrger-kim"}>«Бургер Ким»</Link> создан
                                совершенно по-новому, без использования
                                дрожжевого теста. Вместо этого используются две подушки из риса — продукта без глютена и
                                добавок. А внутри — мраморная говядина, курица или лосось, качественные и свежие овощи.
                            </div>
                            {!this.context.context.settings.isMobile ? null :
                                <Link to={"menu/byrger-kim"} className={styles.hitMenuButton}>
                                    меню бургеры ким
                                </Link>}
                        </Collapse>
                    </div>
                    <div className={styles.hitMenu}>
                        <div className={styles.hitMenuHead}>
                            <div className={styles.hitMenuTitle} onClick={() => this.setState({active: 2})}>
                                <span>САЛАТ КИМЧИ</span>
                                <img src={arrow} alt=""/>
                            </div>
                            {this.context.context.settings.isMobile ? null :
                                <Link to={"/menu/salati-i-zakyski"} className={styles.hitMenuButton}>
                                    меню cалаты
                                </Link>}
                        </div>
                        <Collapse isOpened={this.state.active === 2} theme={{collapse: styles.collapse}}>
                            <div className={styles.hitMenuText}>
                                Чтобы понять Корею, нужно попробовать страну на вкус и проникнуться ее ценностями. И
                                кимчи — это именно то блюдо, которое поможет вам почувствовать себя корейцем. Кимчи —
                                это не только вкусно, но и полезно для здоровья. Ведь в процессе ферментации овощей
                                образуется микрофлора, которая улучшает пищеварение, снимает симптомы простуды и
                                способствует общему укреплению иммунитета. Попробуйте это захватывающее сочетание
                                маринованной капусты с пряностями, и вы поймете, почему кимчи является таким любимым
                                блюдом не только в Корее, но и во всем мире.
                            </div>
                            {!this.context.context.settings.isMobile ? null :
                                <Link to={"/menu/salati-i-zakyski"} className={styles.hitMenuButton}>
                                    меню cалаты
                                </Link>}
                        </Collapse>
                    </div>
                    <div className={styles.hitMenu}>
                        <div className={styles.hitMenuHead}>
                            <div className={styles.hitMenuTitle} onClick={() => this.setState({active: 3})}>
                                <span>КИМПАБЫ - КОРЕЙСКИЕ РОЛЛЫ</span>
                                <img src={arrow} alt=""/>
                            </div>
                            {this.context.context.settings.isMobile ? null :
                                <Link to={"/menu/salati-i-zakyski"} className={styles.hitMenuButton}>
                                    корейские закуски
                                </Link>}
                        </div>
                        <Collapse isOpened={this.state.active === 3} theme={{collapse: styles.collapse}}>
                            <div className={styles.hitMenuText}>
                                Корейские роллы — кимпабы похожи на японские роллы, но отличаются отсутствием уксуса в
                                рисе, вместо него используется кунжутное масло, поэтому рис имеет приятный привкус. В
                                качестве начинки мы используем тунец, хрустящую курочку, корейские салаты, овощи,
                                васаби. Что делает наш кимпаб настолько особенным? Во-первых, это натуральные и свежие
                                ингредиенты, которые мы тщательно отбираем для каждого блюда. Во-вторых, это удобный
                                формат блюда, который вы можете легко взять с собой на работу, на пикник или просто
                                насладиться им дома.
                            </div>
                            {!this.context.context.settings.isMobile ? null :
                                <Link to={"/menu/salati-i-zakyski"} className={styles.hitMenuButton}>
                                    корейские закуски
                                </Link>}
                        </Collapse>
                    </div>
                    <div className={styles.hitMenu}>
                        <div className={styles.hitMenuHead}>
                            <div className={styles.hitMenuTitle} onClick={() => this.setState({active: 4})}>
                                <span>ПАРОВЫЕ ПИРОЖКИ ПЕГОДЯ</span>
                                <img src={arrow} alt=""/>
                            </div>
                            {this.context.context.settings.isMobile ? null :
                                <Link to={"/menu/salati-i-zakyski"} className={styles.hitMenuButton}>
                                    корейские закуски
                                </Link>}
                        </div>
                        <Collapse isOpened={this.state.active === 4} theme={{collapse: styles.collapse}}>
                            <div className={styles.hitMenuText}>
                                Наша паровая булочка пегодя — это идеальное легкое блюдо, которое поможет вам
                                переключиться с рабочего режима на отдых. Она подойдет для перекуса в течение дня или в
                                качестве закуски к основному блюду. А благодаря отсутствию сахара, стабилизаторов и
                                разрыхлителей, пегодя не только вкусная, но и полезная.
                            </div>
                            {!this.context.context.settings.isMobile ? null :
                                <Link to={"/menu/salati-i-zakyski"} className={styles.hitMenuButton}>
                                    корейские закуски
                                </Link>}
                        </Collapse>
                    </div>
                </div>
            </div>
            <div className={styles.disclamer}>
                <h2 className={"title"}>
                    <span className='color-black'>{"Наслаждение едой в"}</span><br/>
                    <span className='color-green'>{"корейском уличном стиле"}</span>
                </h2>
                <div className={styles.disclamerSection}>
                    <div className={styles.disclamerText}>
                        <p className={styles.text}>Несмотря на то что «Koreana Light» — корейский стрит-фуд, он способен
                            впечатлить особенным
                            характером не меньше полноформатного ресторана. Гастрономическая точка оформлена в приятных
                            бежевых тонах и выделяется в ряду других мягким освещением и уютной атмосферой, за которую
                            также
                            отвечают деревянные каркасы, отсылающие к корейским домам ханокам и контраст темных и
                            светлых
                            элементов под древесину. Окажитесь на оживленной улочке Сеула или Пусана.. в центре Москвы с
                            «Koreana Light».</p>
                        <div className={styles.disclamerButtons}>
                            <Link to={"/menu"} className={styles.disclamerButtonGreen}>открыть меню</Link>
                            <Link to={"/vacancies"}className={styles.disclamerButtonWhite}>вакансии</Link>
                        </div>
                    </div>
                    <div className={styles.disclamerImages}>
                        <img src={moscow} alt="Корнер стрит фуд корейской кухни Кореана Light в Москве"/>
                        <img src={green} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    }
}

Moscow.contextType = AppContext;