import React from 'react'

import {AppContext} from "../store/context";
import {Title} from "../components/Typo";
import {CartProduct} from "../components/Cart";
import {Checkout} from "../components/Checkout";
import {ProductCardSlider, ProductCardSimpleSlider} from '../components/product/Card'

export class Cart extends React.Component {
    constructor() {
        super();
    }

    componentDidMount() {
        if (this.context.context.settings.isMobile) {
            return this.props.history.push('/checkout')
        }

        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    componentDidUpdate() {
        if (Object.entries(this.context.context.cart.state.products).length === 0) {
            this.context.context.toast.warn('Корзина пуста :(');

            return this.props.history.push('/menu')
        }
    }

    render() {
        const products = Object.entries(this.context.context.cart.state.products).map(el => {
            return {
                ulid: el[0],
                ...el[1]
            }
        });

        const sauces = this.context.context.catalog.state.recommendations
            ? Object.entries(this.context.context.catalog.state.recommendations.sauces).map(el => {
                return {
                    ulid: el[0],
                    ...el[1]
                }
            }) : [];

        const dop = this.context.context.catalog.state.recommendations
            ? Object.entries(this.context.context.catalog.state.recommendations.dop).map(el => {
            return {
                ulid: el[0],
                ...el[1]
            }
        }) : [];



        return <div className="cart reveal">
            <div className="container container-pd">
                <div className="cart__title">
                    <Title black={'корзина'}/>
                </div>
                <div className="cart__inner">
                    <div className="cart__products">
                        {products.map(el => {
                            return <CartProduct product={el}/>
                        })}
                    </div>
                    <div className="cart__checkout">
                        <Checkout/>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="cart__sauces">
                    {sauces.length > 0
                        ? <>
                            <div className="title">
                                <span className='color-green'>Не забудьте </span>
                                <span className='color-black'>Добавить:</span>
                            </div>
                            <ProductCardSimpleSlider products={sauces}/>
                        </>
                        : null}
                </div>
            </div>
            <div className="container">
                <div className="cart__recommendations">
                    {dop.length > 0
                        ? <>
                            <div className="title">
                                <span className='color-black'>Рекомендуем </span>
                                <span className='color-green'>попробовать:</span>
                            </div>
                            <ProductCardSlider products={dop}/>
                        </>
                        : null}
                </div>
            </div>
        </div>
    }
}

Cart.contextType = AppContext;