export const CommonInitialState = {
    seoText: null,
    menuBlock: null
};
export const CommonReducer = (state, action) => {
    switch (action.type) {
        case 'setSeoText':
            return {...state, seoText: action.payload};
        case 'setMenuBlockText':
            return {...state, menuBlock: action.payload};
        default:
            throw new Error();
    }
};

export const UserInitialState = {
    user: {},
    isAuth: false
};
export const UserReducer = (state, action) => {
    switch (action.type) {
        case 'setUser':
            return {...state, user: action.payload, isAuth: true};
        case 'exit':
            return UserInitialState
        default:
            throw new Error();
    }
};

export const RestaurantReducer = (state, action) => {
    switch (action.type) {
        case 'setRestaurants':
            return {...state, items: action.payload};
        case 'setSelected':
            return {...state, selected: action.payload};
        case 'setBackground':
            return {...state, background: action.payload};
        default:
            throw new Error();
    }
};
export const RestaurantInitialState = {
    items: [],
    selected: null,
    background: null
};

export const AuthModalInitialState = {
    open: false,
    selected: null,
    openExit: false,
};
export const AuthModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        case 'toggle':
            return {...state, open: !state.open};
        case 'toggleExit':
            return {...state, openExit: !state.openExit};
        default:
            throw new Error();
    }
}


export const NewYearModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        default:
            throw new Error();
    }
};
export const NewYearModalInitialState = {
    open: false,
};


export const RestaurantModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            if (action && action.payload) {
                return {...state, open: true, product: action.payload};
            }

            return {...state, open: true};
        case 'close':
            if (state.product) {
                return {...state, open: false, product: null};
            }

            return {...state, open: false};
        case 'select':
            return {...state, selected: action.payload};
        default:
            throw new Error();
    }
};
export const RestaurantModalInitialState = {
    open: false,
    selected: null,
    product: null
};

export const ProductModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {
                ...state,
                open: false,
                selectedModifiers: {
                    sauces: {},
                    toppings: {}
                }
            };
        case 'toggle':
            return {...state, open: !state.open};
        case 'openProduct':
            return {...state, open: true, selected: action.payload.product};
        case 'setList':
            return {...state, list: action.payload.products};
        case 'setModifiersSauces':
            return {
                ...state,
                selectedModifiers: {
                    ...state.selectedModifiers,
                    sauces: action.payload,
                }
            };
        case 'setModifiersToppings':
            return {
                ...state,
                selectedModifiers: {
                    ...state.selectedModifiers,
                    toppings: action.payload,
                }
            };
        case 'setIsDisabledAddToCartBtn':
            return {
                ...state,
                isDisabledAddToCartBtn: action.payload.disabled
            };
        default:
            throw new Error();
    }
};
export const ProductModalInitialState = {
    open: false,
    list: null,
    selected: null,
    isDisabledAddToCartBtn: true,
    selectedModifiers: {
        sauces: {},
        toppings: {}
    }

};

export const CartReducer = (state, action) => {
        switch (action.type) {
            case 'setBubblePos':
                return {...state, bubble: action.payload};
            case 'open':
                return {...state, modal: {...state.modal, open: true}};
            case 'close':
                return {...state, modal: {...state.modal, open: false}};
            case 'toggleModal':
                return {...state, modal: {...state.modal, open: !state.modal.open}};
            case 'clearCart':
                return CartInitialState;
            case 'setCart':
                return {
                    ...state,
                    sum: action.payload.sum,
                    count: action.payload.count,
                    products: action.payload.products,
                    recommendations: action.payload.recommendations,
                    delivery: {
                        ...state.delivery,
                        type: action.payload.delivery.type,
                        zone: {
                            id: action.payload.delivery.zone.id,
                            group: action.payload.delivery.zone.group,
                            distance: action.payload.delivery.zone.distance
                        },
                    },
                    details: {
                        ...state.delivery.details,
                        verified: action.payload.details.verified,
                    },
                };
            case 'setZone':
                return {
                    ...state,
                    delivery: {
                        ...state.delivery,
                        zone: {
                            id: action.payload.zone.id,
                            group: action.payload.zone.group,
                            distance: action.payload.zone.distance
                        }
                    }
                };
            default:
                throw new Error();
        }
    }
;
export const CartInitialState = {
    modal: {
        open: false,
    },
    sum: 0,
    products: [],
    recommendations: [],
    delivery: {
        type: 'pickup',
        zone: {
            distance: null,
            group: null,
            id: null
        },
    },
    details: {
        verified: false
    },
    bubble: {
        x: 0,
        y: 0
    }
};

export const CatalogReducer = (state, action) => {
    switch (action.type) {
        case 'setQuery':
            return {...state, query: action.payload};
        case 'setCategories':
            return {...state, categories: {...state.categories, list: action.payload}};
        case 'setRecommendations':
            return {...state, recommendations: action.payload};
        case 'sort':
            return {...state, sort: {...state.sort, selected: action.payload}};
        case 'filter':
            return {...state, filters: {...state.filters, selected: action.payload}};
    }
};
export const CatalogInitialState = {
    query: '',
    filters: {
        selected: null
    },
    sort: {
        selected: null
    },
    categories: {
        list: [],
        selected: null
    },
    recommendations: null
};

export const ActionModalInitialState = {
    open: false,
    list: [],
    selected: null
}
export const ActionModalReducer = (state, action) => {
    switch (action.type) {
        case 'setList':
            return {...state, list: action.payload};
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        case 'toggle':
            return {...state, open: !state.open};
        case 'select':
            return {...state, selected: action.payload}
    }
};

export const VacancyModalInitialState = {
    open: false,
    title: null,
    fields: {
        name: null,
        phone: null,
        email: null,
        comment: null,
        file: null,
    },
};

export const VacancyModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true, title: action.payload};
        case 'close':
            return {...state, open: false};
    }
};
