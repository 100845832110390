import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/user`;

export class User extends Base {
    loadUser() {
        return this.fetchJSON(`${BASE_URL}/`).then(response => {
            return response.data
        });
    }

    authByPhone(phone) {
        return this.fetchJSON(`${BASE_URL}/auth/phone`, {
            method: 'POST',
            body: JSON.stringify({phone: phone})
        }).then(response => {
            return response.data
        });
    }

    verifyCode(code, phone) {
        return this.fetchJSON(`${BASE_URL}/auth/phone/confirm`, {
            method: 'POST',
            body: JSON.stringify({code: code, phone: phone})
        }).then(response => {
            return response.data
        });
    }

    logout() {
        return this.fetchJSON(`${BASE_URL}/logout`)
    }
}