import React, {useContext} from 'react';
import {Link} from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import {BottomSheet} from 'react-spring-bottom-sheet'

import {CATALOG_FILTERS} from "../const";

import {ProductCardGrid} from "../components/product/Card";
import {RestaurantPickupDropdown} from "../components/Dropdown";
import {SearchInput} from '../components/Form'
import {Button} from "../components/Buttons";
import {Loader} from "../components/Loader";
import {MenuSeo} from "../components/Seo";
import {AppContext} from "../store/context";

export class Catalog extends React.Component {
    constructor(props, context) {
        super();

        this.processor = context.context.processor;

        this.onDismiss = this.onDismiss.bind(this);
        this.openBottomSheet = this.openBottomSheet.bind(this);

        this.state = {
            bottomSheetOpen: false,
            loaded: false,
            products: [],
        }
    }

    categoryFromProps(props) {
        return props.match.params.hasOwnProperty('slug')
            ? props.match.params.slug
            : null
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        if (this.props.location.search) {
            const params = new URLSearchParams(this.props.location.search);
            const query = params.get('search');
            const popup = params.get('openAddress');

            if (query) {
                this.setState({loaded: false,});

                this.processor.catalog.search(query).then(response => {
                    const products = response.products;

                    this.setState({...this.state, products: products});

                    return products;
                }).then(products => {
                    this.setState({loaded: true});
                    this.context.context.modals.product.dispatch({type: 'setList', payload: {products: products}})
                }).catch(err => {
                    this.props.history.push('/menu')
                });

                return;
            }

            if (popup) {
                if (!this.context.context.cart.state.delivery.zone.group) {
                    this.context.context.modals.restaurant.dispatch({type: 'open'})
                }
            }
        }

        const category = this.categoryFromProps(this.props) || 'all';

        this.processor.catalog.category(category).then(response => {
            const products = response.products;

            this.setState({
                ...this.state,
                products: products,
            });

            return products;
        }).then(products => {
            this.setState({loaded: true});
            this.context.context.modals.product.dispatch({type: 'setList', payload: {products: products}})
        }).catch(err => {
            this.props.history.push('/menu')
        })
    }

    componentDidUpdate(prevProps) {
        if (this.categoryFromProps(this.props) !== this.categoryFromProps(prevProps)) {
            window.scrollTo({top: 0, left: 0, behavior: "smooth"});
            const params = new URLSearchParams(this.props.location.search);
            const filter = params.get('filter');
            const category = this.categoryFromProps(this.props) || 'all';

            this.setState({loaded: false,});

            this.processor.catalog.category(category).then(response => {
                const products = response.products;

                this.setState({...this.state, products: products});

                return products;
            }).then(products => {
                this.setState({loaded: true});
                filter
                    ? this.filter(filter)
                    : this.context.context.catalog.dispatch({type: 'filter', payload: null});
                this.context.context.modals.product.dispatch({type: 'setList', payload: {products: products}})
            }).catch(err => {
                this.props.history.push('/menu')
            });

            return;
        }

        if (this.props.location.search !== prevProps.location.search) {
            window.scrollTo({top: 0, left: 0, behavior: "smooth"});

            const params = new URLSearchParams(this.props.location.search);
            const query = params.get('search');
            const filter = params.get('filter');

            this.setState({loaded: false,});

            if (query) {
                this.processor.catalog.search(query).then(response => {
                    const products = response.products;

                    this.setState({...this.state, products: products});

                    return products;
                }).then(products => {
                    this.setState({loaded: true});

                    filter
                        ? this.filter(filter)
                        : this.context.context.catalog.dispatch({type: 'filter', payload: null});

                    this.context.context.modals.product.dispatch({type: 'setList', payload: {products: products}})
                }).catch(err => {
                    this.props.history.push('/menu')
                })

                return;
            }

            this.processor.catalog.category('all').then(response => {
                const products = response.products;

                this.setState({...this.state, products: products});

                return products;
            }).then(products => {
                this.setState({loaded: true});
                filter
                    ? this.filter(filter)
                    : this.context.context.catalog.dispatch({type: 'filter', payload: null});

                this.context.context.modals.product.dispatch({type: 'setList', payload: {products: products}})
            }).catch(err => {
                this.props.history.push('/menu')
            });
        }
    }

    openBottomSheet() {
        this.setState({
            ...this.state,
            bottomSheetOpen: true,
        })
    }

    onDismiss() {
        this.setState({
            ...this.state,
            bottomSheetOpen: false,
        })
    }

    filter(type) {
        if (this.props.location.pathname !== '/menu') {
            this.props.history.push(`/menu?filter=${type}`);
            return
        }

        this.context.context.catalog.dispatch({
            type: 'filter',
            payload: this.context.context.catalog.state.filters.selected === type ? null : type
        })
    }

    desktop() {
        const categories = this.context.context.catalog.state.categories.list;

        return <div className="container catalog">
            <div className="catalog-row">
                <MenuSeo/>
                <div className="catalog-filters">
                    <div className="catalog-filters__sort">
                        {/*<SortDropdown/>*/}
                    </div>
                    <div
                        className={`catalog-filters__labels ${this.context.context.catalog.state.filters.selected ? 'selected' : ''}`}>
                        {CATALOG_FILTERS.map(el => {
                            return <div onClick={() => this.filter(el.type)}
                                        className={`catalog-filters__filter ${this.context.context.catalog.state.filters.selected === el.type ? 'active' : ''}`}>
                                <div className="catalog-filters__filter-title">
                                    <div className="text">
                                        {el.title}
                                    </div>
                                </div>
                                {el.icon
                                    ? <div className="catalog-filters__filter-icon">
                                        <img src={el.icon} alt=""/>
                                    </div>
                                    : null}
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <div className="catalog-holder">

                <div className="catalog-col">
                    <div className="catalog-col--clearfix"/>
                    <div className="catalog-menu">
                        <div className="catalog-menu__zone">
                            <RestaurantPickupDropdown/>
                        </div>
                        <div className="catalog-menu__search">
                            <SearchInput/>
                        </div>
                        <div className="catalog-menu__hit">
                            <Button color={'green'}
                                    className="catalog-menu__hit-button"
                                    text={'ХИТ-ЛИСТ'}
                                    onClick={() => this.props.history.push('/menu/hits')}/>
                        </div>
                        <div className="catalog-menu__categories">
                            <div className="catalog-menu__categories--title">Меню</div>
                            <div className="catalog-menu__categories--items">
                                {categories ? categories.map(el => {
                                    return <div className={"catalog-menu__categories--item"}>
                                        <Link className="" to={`/menu/${el.slug}`}>{el.title}</Link>
                                        <span>{el.count}</span>
                                    </div>
                                }) : <Skeleton/>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="catalog-col">

                    {this.state.loaded ? <div className="catalog-products">
                        <ProductCardGrid products={this.state.products}/>
                    </div> : <Loader/>}
                </div>
            </div>
        </div>
    }

    mobile() {
        const categories = this.context.context.catalog.state.categories.list;

        return <div className="container catalog">
            <MenuSeo/>
            <div className="catalog-holder">
                <div className="catalog-menu">
                    <div className="catalog-menu__zone">
                        <RestaurantPickupDropdown/>
                    </div>
                </div>
                <div className="catalog-filters">
                    <div className="catalog-filters__sort">
                        {/*<SortDropdown/>*/}
                    </div>
                    <div className="catalog-filters__hit">
                        <Button color={'green'}
                                className="catalog-menu__hit-button"
                                text={'ВСЕ МЕНЮ'}
                                onClick={() => this.openBottomSheet()}/>
                    </div>
                    <div className="catalog-filters__labels">
                        {CATALOG_FILTERS.map(el => {
                            return <div onClick={() => {
                                this.filter(el.type)
                            }}
                                        className={`catalog-filters__filter ${this.context.context.catalog.state.filters.selected === el.type ? 'active' : ''}`}>
                                <div className="catalog-filters__filter-title">
                                    <div className="text">
                                        {el.title}
                                    </div>
                                </div>
                                {el.icon ? <div className="catalog-filters__filter-icon">
                                    <img src={el.icon} alt=""/>
                                </div> : null}
                            </div>
                        })}
                    </div>
                </div>
                {this.state.loaded ? <div className="catalog-products">
                    <ProductCardGrid products={this.state.products}/>
                </div> : <Loader/>}

            </div>
            <MobileSection categories={categories} closeMenu={this.onDismiss} openMenu={this.openBottomSheet}
                           isOpen={this.state.bottomSheetOpen}/>
        </div>
    }

    render() {
        return this.context.context.settings.isMobile
            ? this.mobile()
            : this.desktop()
    }
}

const MobileSection = ({categories, isOpen, openMenu, closeMenu}) => {
    const {context} = useContext(AppContext);

    const openCart = () => {
        if (context.cart.state.sum !== 0) {
            context.cart.dispatch({type: 'open'})
        }
    };

    const cartTitle = () => {
        const title = context.cart.state.sum === 0
            ? 'Пуста'
            : `${context.cart.state.sum}₽`

        return <><span>КОРЗИНА</span> <span>{title}</span></>
    };

    return <>
        <div className="catalog-buttons">
            <div className="button button-green catalog-button--menu" onClick={() => {
                openMenu()
            }}><span>МЕНЮ</span></div>
            <div className="button button-black catalog-button--cart" onClick={() => {
                openCart()
            }}>{cartTitle()}</div>
            <div className="catalog-buttons--blur"/>
        </div>
        <BottomSheet
            class={'small-modal'}
            header={<div className="modal-header-clearfix"></div>}
            open={isOpen} onDismiss={closeMenu} snapPoints={({maxHeight}) => maxHeight / 1.6}>
            <div className="catalog-menu__categories--items">
                {categories ? categories.map(el => {
                    return <div className="catalog-menu__categories--item">
                        <Link onClick={() => {
                            closeMenu()
                        }} to={`/menu/${el.slug}`}>{el.title}</Link>
                        <span>{el.count}</span>
                    </div>
                }) : <Skeleton/>}
            </div>
        </BottomSheet>
    </>
};

Catalog.contextType = AppContext;
