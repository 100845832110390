import React from "react";
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import {Head} from '../components/Seo'

import routes from "./index";

const Routes = () => (
    <Router>
        <Head/>
        <Switch>
            {RouteMap()}
            <Redirect to={"/404"}/>
        </Switch>
    </Router>
);


const RouteMap = () => {
    let map = [];

    routes.forEach((el, index) => {
        map.push(componentRoute(el, index));
    });

    return map
};

const componentRoute = ({layout: Layout, component: Component, path}, index) => {
    return Component ? (<Route key={index} path={path} exact render={(props) => (
        <Layout><Component {...props} /></Layout>
    )}/>) : null;
};

export default Routes;
