export const Title = ({black, green}) => {
    return  <div className="title">
        <span className='color-black'>{black}</span>
        <span className='color-green'>{green}</span>
    </div>
};

export const TitleWGap = ({black, green}) => {
    return  <div className="title">
        <span className='color-black'>{black}</span><br/>
        <span className='color-green'>{green}</span>
    </div>
};