import React, {useContext} from 'react'
import {STATIC_HOST} from "../const";
import {AppContext} from "../store/context";

export const CartProducts = ({products}) => {
    return products.map(el => {
        return <CartProduct product={el}/>
    })
};

export const CartProduct = ({product}) => {
    const {context} = useContext(AppContext);
    const addProduct = (uid) => {
        context.processor.cart.addProduct({uid: uid}).then((response) => {
            context.cart.dispatch({type: 'setCart', payload: response.cart})
        }).catch(err => {
            if (err.code === 401) {
                context.modals.restaurant.dispatch({type: 'open', payload: {uid: uid}});
            }
            if (err.code === 400) {
                context.toast.error(err.message)
            }
        });
    };

    const removeProduct = (uid) => {
        context.processor.cart.removeProduct({uid: uid}).then((response) => {
            context.cart.dispatch({type: 'setCart', payload: response.cart})

            if (response.cart.sum === 0) {
                context.cart.dispatch({type: 'close'})
            }
        }).catch(err => {
            if (err.code === 401) {
                context.modals.restaurant.dispatch({type: 'open'})
            }
        });
    };

    const clearProduct = (uid) => {
        context.processor.cart.clearProduct({uid: uid}).then((response) => {
            context.cart.dispatch({type: 'setCart', payload: response.cart})

            if (response.cart.sum === 0) {
                context.cart.dispatch({type: 'close'})
            }
        }).catch(err => {
            if (err.code === 401) {
                context.modals.restaurant.dispatch({type: 'open'})
            }
        });
    };

    return <div className="cart_product-card" key={product.ulid}>
        <div className="cart_product-card__image"
             style={{backgroundImage: `url(${STATIC_HOST + product.image})`}}/>
        <div className="cart_product-card__info">
            <div className="cart_product-card__title">
                <span>{product.name}</span>
                {product.tags.find(el => el === 'specakciya') ? <div className={"specakciya"}>акция</div> : null}
            </div>
            <div
                className="cart_product-card__option">{product.modifiers.length > 0 ? `Топпинги: ${product.modifiers.map(el => {
                return el.name
            }).join(',')}` : ""}</div>
            <div className="cart_product-card__bottom">
                <div className="cart_product-card__counter">
                    <div className="cart_product-card__counter-button cart_product-card__counter-button--minus"
                         onClick={() => {
                             product.quantity === 1 ? clearProduct(product.ulid) : removeProduct(product.ulid)
                         }}>-
                    </div>
                    <div className="cart_product-card__counter-value">{product.quantity}</div>
                    <div className="cart_product-card__counter-button cart_product-card__counter-button--plus"
                         onClick={() => {
                             addProduct(product.ulid)
                         }}>+
                    </div>
                </div>
                <div className="cart_product-card__price">
                    <div className="cart_product-card__price--label">Сумма:</div>
                    <div className="cart_product-card__price--value">{product.price * product.quantity}₽
                    </div>
                </div>
            </div>
        </div>
    </div>
}