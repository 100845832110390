export const Button = ({color, text, onClick, disabled = false}) => {
    let buttonClass = '';

    switch (color) {
        case 'white':
            buttonClass = 'button-white';
            break;
        case 'green':
        default:
            buttonClass = 'button-green';
    }

    if (disabled) {
        buttonClass += ' button-disabled';
    }

    return (<div className={'button '  + buttonClass} onClick={onClick}>
            <span>{text}</span>
        </div>
    )
};
