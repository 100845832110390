import React, {useContext, useState} from "react";
import Slider from "react-slick";
import {Button} from '../Buttons'
import {AppContext} from "../../store/context";
import {SortMap, STATIC_HOST} from "../../const";

import hit from '../../static/icons/star.svg'
import hot from '../../static/icons/hot.svg'
import vegan from '../../static/icons/vegan.svg'

const ProductTagIcon = ({tags}) => {
    let icon = null;

    if (tags.length) {
        for (let i = 0; i < tags.length; i++) {

            switch (tags[i].slug) {
                case 'spicy':
                    icon = hot;
                    break;
                case 'vegan':
                    icon = vegan;
                    break;
                case 'hit-list':
                    icon = hit;
                    break;

            }
        }
    }

    return icon ? <div className="icon">
        <img src={icon} alt=""/>
    </div> : null
};

export const ProductCardModifier = ({title, icon, onClick}) => {
    return <div className="product-card__modifier" onClick={onClick}>
        <div className="text">{title}</div>
        <div className="icon">
            <img src={icon} alt=""/>
        </div>
    </div>
};

export const ProductCard = ({product, small}) => {
    const {context} = useContext(AppContext);
    const [show, setShow] = useState(false);
    const [cursorSliderStartX, setCursorSliderStartX] = useState(0);
    const [cursorSliderStartY, setCursorSliderStartY] = useState(0);

    small = small || false;

    const openCard = (product) => {
        // if (small) {
        context.modals.product.dispatch({type: 'openProduct', payload: {product: product}})
        // }
    };

    const showModifiers = () => {
        setShow(true);
    };

    const handleCardPointerDown = (evt) => {
        setCursorSliderStartX(evt.pageX);
        setCursorSliderStartY(evt.pageY);
    };

    const handleCardPointerUp = (evt) => {
        const delta = 6;
        const diffX = Math.abs(evt.pageX - cursorSliderStartX);
        const diffY = Math.abs(evt.pageY - cursorSliderStartY);

        if (diffX < delta && diffY < delta) {
            if (!product.disabled) {
                openCard(product);
            }
        }
    };

    return <div className={`product-card ${small ? 'product-card--small' : ''} ${product.disabled ? 'product-card--disabled' : ''}`} onPointerDown={handleCardPointerDown} onPointerUp={handleCardPointerUp}>
        <div className={`product-card__picture ${product.disabled || show ? 'shaded' : ''}`}>
            {product.disabled ? <div className="product-card__disabled">Блюдо недоступно</div> : null}
            <div className={`product-card__modifiers ${show ? "show" : ""}`}>

            </div>
            <div className="product-card__picture-image"
                 style={{backgroundImage: `url('${product.images.preview ? STATIC_HOST + product.images.preview.source : ''}')`}}
            />
        </div>
        <div className="product-card__body">
            <div className="product-card__body-title">
                <div className="text">
                    <span>{product.title.ru}</span>
                    {product.tags.find(el => el.slug === 'specakciya') ? <div className={"specakciya"}>акция</div> : null}
                </div>
                <ProductTagIcon tags={product.tags}/>
            </div>
            <div className="product-card__body-description">
                <div className="text">{product.description.ru}</div>
            </div>
            <div className="product-card__body-footer">
                <div className="product-card__body-footer_weight">{product.weight} / {product.nutrition.energy} ккал
                </div>
                <div className="product-card__body-footer_priceblock">
                    {product.oldPrice ?
                        <span className="product-card__body-footer-oldprice">{product.oldPrice}</span> : null}
                    <span className="product-card__body-footer_price"> {`${product.price}₽`}</span>
                </div>
                <div className="product-card__body-footer_button">
                    {!product.disabled ? <Button color={'green'} text={'+'}/> : null}
                </div>
            </div>
        </div>
    </div>
};

const settings = {
    speed: 500,
    arrows: false,
    draggable: true,
    variableWidth: true,
    infinite: true,
    autoplay: true,
    pauseOnHover: true,
};

export const ProductCardSlider = ({products, small}) => {
    return <div className="product-cards-slider">
        <Slider {...settings}>
            {products.map(product => {
                return <ProductCard product={product} small={small}/>
            })}
        </Slider>
    </div>
};

export const ProductCardSimpleSlider = ({products}) => {
    const prods = products.slice(0, 2)

    return <div className="product-cards-slider">
        {prods.map(product => {
            return <ProductCardSimple product={product}/>
        })}
    </div>
}

export const ProductCardGrid = ({products}) => {
    const {context} = useContext(AppContext);

    const zone = context.cart.state.delivery.zone.group;
    const sort = context.catalog.state.sort.selected;
    const filter = context.catalog.state.filters.selected;

    if (zone) {
        products.map(el => {
            el.disabled = !el.zones.find(z => {
                return z.id === zone
            });
        });
    }

    if (filter) {
        products = products.filter(el => {
            return el.tags.find(t => {
                return t.slug === filter
            })
        });
    }

    if (sort) {
        products.sort(SortMap(sort));
    }

    return products.map(el => {
        return <ProductCard product={el} small={true}/>
    })
};

export const ProductCardSimple = ({product}) => {
    const {context} = useContext(AppContext);

    const addToCart = (event, product) => {
        context.processor.cart.addProduct({product: product}).then((response) => {
            context.cart.dispatch({type: 'setBubblePos', payload: {x: event.clientX, y: event.clientY}});
            context.cart.dispatch({type: 'setCart', payload: response.cart})
        }).catch(err => {
            if (err.code === 401) {
                context.modals.restaurant.dispatch({type: 'open', payload: {product: product}});
            }
            if (err.code === 400) {
                context.toast.error(err.message)
            }
        });
    };

    return <div className={`product-card--simple ${product.disabled ? 'product-card--disabled' : ''}`}>
        <div className={`product-card__picture ${product.disabled ? 'shaded' : ''}`}>
            {product.disabled ? <div className="product-card__disabled">Блюдо недоступно</div> : null}
            <div className="product-card__picture-image"
                 style={{backgroundImage: `url('${product.images.preview ? STATIC_HOST + product.images.preview.source : ''}')`}}
            />
        </div>
        <div className="product-card__body">
            <div className="product-card__body-title">
                <div className="text">
                    <span>{product.title.ru}</span>
                    {product.tags.find(el => el.slug === 'specakciya') ?
                        <div className={"specakciya"}>акция</div> : null}
                </div>
            </div>
            <div className="product-card__body-footer">
                <div className="product-card__body-footer_label">Сумма:</div>
                <div className="product-card__body-footer_priceblock">
                    {product.oldPrice ?
                        <span className="product-card__body-footer-oldprice">{product.oldPrice}</span> : null}
                    <span className="product-card__body-footer_price"> {`${product.price}₽`}</span>
                </div>
                <div className="product-card__body-footer_button">
                    {!product.disabled ? <Button onClick={(e) => {
                        addToCart(e, product.id)
                    }} color={'green'} text={'+'}/> : null}
                </div>
            </div>
        </div>
    </div>
}

export const CartSauce = ({product}) => {
    const {context} = useContext(AppContext);

    const addToCart = (product) => {
        context.processor.cart.addProduct({product: product}).then((response) => {
            context.cart.dispatch({type: 'setCart', payload: response.cart})
        }).catch(err => {
            if (err.code === 401) {
                context.modals.restaurant.dispatch({type: 'open', payload: {product: product}});
            }
            if (err.code === 400) {
                context.toast.error(err.message)
            }
        });
    };

    return <div className="cart-sauce">
        <div className="cart-sauce__image"
             style={{backgroundImage: `url('${product.images.preview ? STATIC_HOST + product.images.preview.source : ''}')`}}/>
        <div className="cart-sauce__title">
            <div className="text">{product.title.ru}</div>
        </div>
        <div className="cart-sauce__footer">
            <div className="cart-sauce__button">
                {!product.disabled ? <Button onClick={() => {
                    addToCart(product.id)
                }} color={'green'} text={'+'}/> : null}
            </div>
            <div className="cart-sauce__price">
                <div className="cart-sauce__price_label">Сумма:</div>
                <div className="cart-sauce__price_value">{`${product.price}₽`}</div>
            </div>
        </div>
    </div>
}
