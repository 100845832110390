import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/cart`;

export class Cart extends Base {
    selectPickupRestaurant(el) {
        return this.fetchJSON(`${BASE_URL}/selectPickupRestaurant`, {
            method: 'POST',
            body: JSON.stringify({id : el.id})
        }).then(response => {
            return response.data
        })
    }

    addProduct({product, modifiers, uid}) {
        product= product || null
        modifiers = modifiers || []
        uid = uid || null

        return this.fetchJSON(`${BASE_URL}/product/add`, {
            method: 'POST',
            body: JSON.stringify({product : product, modifiers: modifiers, uid: uid})
        }).then(response => {
            return response.data
        })
    }

    removeProduct({uid}) {
        return this.fetchJSON(`${BASE_URL}/product/remove`, {
            method: 'POST',
            body: JSON.stringify({uid: uid})
        }).then(response => {
            return response.data
        })
    }

    clearProduct({uid}) {
        return this.fetchJSON(`${BASE_URL}/product/clear`, {
            method: 'POST',
            body: JSON.stringify({uid: uid})
        }).then(response => {
            return response.data
        })
    }

    loadCart() {
        return this.fetchJSON(`${BASE_URL}/`).then(response => {
            return response.data
        })
    }

    checkout(data) {
        return this.fetchJSON(`${BASE_URL}/checkout`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(response => {
            return response.data
        });
    }

    verifyPhone(phone) {
        return this.fetchJSON(`${BASE_URL}/verify/phone`, {
            method: 'POST',
            body: JSON.stringify({phone: phone})
        }).then(response => {
            return response.data
        });
    }

    verifyCode(phone, code) {
        return this.fetchJSON(`${BASE_URL}/verify/code`, {
            method: 'POST',
            body: JSON.stringify({phone: phone, code: code})
        }).then(response => {
            return response.data
        });
    }
}